//const  API_URL = "http://dev.kioskp.com/app_dev.php/api";
import {API_URL,checkStatus} from './common';
import Translator  from '../translations';


async function logout(apiKey, cb) {
  let finalApiKey = apiKey || -1;

    return fetch(API_URL+'/logout', {
            accept: 'application/json',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              locale: Translator.locale,
              Authorization:finalApiKey,
            },
          })
          .then(checkStatus)
          .then(cb);
}

async function uploadUserPicture(apiKey, file) {

    let formData = new FormData();
    formData.append("upload_user_picture_type[avatar]",  file);

    return fetch(API_URL+'/user/upload/picture',{
                method: 'post',
                body: formData,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  locale: Translator.locale,
                  Authorization:apiKey,
                },
            })
            .then(checkStatus)
            ;
}

async function removeUserAvatar(apiKey) {

    return fetch(API_URL+'/user/remove_avatar',{
                method: 'delete',
                accept: 'application/json',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  locale: Translator.locale,
                  Authorization:apiKey,
                },
            })
            .then(checkStatus);
}

async function login(parameters, loginByIp) {

  const url = loginByIp ? '/login/ip':'/login';

  return fetch(API_URL+url, {
            method: 'POST',
            accept: 'application/json',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              locale: Translator.locale,
            },
            body: parameters
          })
          .then(checkStatus)
      ;
}
async function fbLogin(parameters) {

  return fetch(API_URL+'/login/fb', {
            method: 'POST',
            accept: 'application/json',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              locale: Translator.locale,
            },
            body: parameters
          })
          .then(checkStatus)
      ;
}

async function recoverCode(parameters) {

  return fetch(API_URL+'/recover/code', {
            method: 'POST',
            accept: 'application/json',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              locale: Translator.locale,
            },
            body: parameters
          })
          .then(checkStatus)
      ;
}

async function recoverPassword(parameters) {
  return fetch(API_URL+'/password/request', {
            method: 'POST',
            accept: 'application/json',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              locale: Translator.locale,
            },
            body: parameters
          })
          .then(checkStatus)
      ;
}

async function resettingPassword(parameters) {
  return fetch(API_URL+'/password/confirm', {
            method: 'POST',
            accept: 'application/json',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              locale: Translator.locale,
            },
            body: parameters
          })
          .then(checkStatus)
      ;
}

async function recoverPasswordOrCode(parameters, mode='password') {

  if (mode.toUpperCase() === 'CODE'){
    return recoverCode(parameters);
  }else {
    return recoverPassword(parameters);
  }
}

async function register(parameters) {
  return fetch(API_URL+'/register', {
            method: 'POST',
            accept: 'application/json',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              locale: Translator.locale,
            },
            body: parameters
          })
          .then(checkStatus)
      ;
}

async function confirmRegistration(token) {
  return fetch(API_URL+'/confirm/'+token, {
            method: 'GET',
            accept: 'application/json',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              locale: Translator.locale,
            },
          })
          .then(checkStatus)
      ;
}

async function profileEdit(apiKey, parameters) {

  return fetch(API_URL+'/user/edit', {
            method: 'POST',
            accept: 'application/json',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              locale: Translator.locale,
              Authorization:apiKey,

            },
            body: parameters
          })
          .then(checkStatus)
      ;

}

async function changePassword(apiKey, parameters) {

  return fetch(API_URL+'/change/password', {
            method: 'POST',
            accept: 'application/json',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              locale: Translator.locale,
              Authorization:apiKey,
            },

            body: parameters
          })
          .then(checkStatus)
      ;
}

async function getUserInfo(apiKey) {

  return fetch(API_URL+'/user/info', {
            method: 'GET',
            accept: 'application/json',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              locale: Translator.locale,
              Authorization:apiKey,
            },
          })
          .then(checkStatus)
      ;
}

const Api = {
    login,register,confirmRegistration, logout,
    changePassword,uploadUserPicture, profileEdit,
    recoverCode, recoverPassword,resettingPassword, recoverPasswordOrCode,
    fbLogin,removeUserAvatar,getUserInfo,
};

export default Api;
