export const es = {
    kiosk: "Quiosco",
    search: "Buscar",
    library: "Biblioteca",
    empty_library: "Su biblioteca esta vacia",
    search_placeholder: "Buscar...",
    settings: "Parámetros",
    write_us:'Escríbanos !',
    rate_us: "Notez-nous dans {{store}}",
    recommend_to_friend: "Recommandez à un ami",
    account: "Mi cuenta",
    about: "A proposito",
    not_connected: "no ha iniciado sesión",
    welcome_to: "Bienvenido a",
    cgu: "CGU",
    log_out: 'Salir',
    contact_us:'Contactarmos !',
    disconnection: 'Cerrar sesión',
    no_internet_access: "No tienes acceso a Internet",
    continue_without_account: "Continuar sin cuenta",
    alert_log_out: {
        title:'Salir',
        message:'¿Seguro que quieres salir de sesión?',
        actions:{
          log_out: 'Salir',
          cancel: 'Anular',
        }
    },
    alert_remove_pub_library:{
      title: 'Confirmación de eliminación',
      message:{
        one:'¿Está seguro de que desea eliminar la revista seleccionada? ',
        other:'¿Está seguro de que desea eliminar las revistas seleccionadas? ',
      },
      actions:{
        cancel: 'Anular',
        delete: 'Eliminar'
      }
    },
    actions:{
        cancel: "Anular",
        show: "Ver",
        read: "Consultar",
        delete: "Eliminar",
        sign_in: "Entrar",
        sign_out: "Registrarse",
        download: "Descargar",
        share: "Compartir",
        rate_app: "Observe la aplicación",
        validate: "Validar",
        edit_avatar: 'Editar tu avatar',
        change_password: 'Cambiar contraseña',
        refresh: 'Actualizar',
    },
    alert_sign_out:{
      title: 'Desconectarse',
      message: 'Are you sure you want to sign out?',
    },
    "security": {
        "login": {
            "username": "nombre del usuario",
            "email": "e-mail",
            "password": "Contraseña",
            "submit": "Entrar"
        }
    },
    profile: {
        profile: "Perfil",
        add_avatar: 'Añadir un avatar',
        edit_avatar: 'Editar mi avatar',
        add_another_account: 'Añadir una cuenta',
        my_personnals_data: "Mi información personal",
        current_kiosk: "Has iniciado sesión actualmente en",
        switch_kiosk_instruction: "Para cambiar la cuenta haga clic en el quiosco (en negro)",
        change_password: "Cambiar contraseña",
        edit: {
            submit: "Actualizar"
        },
        flash: {
            updated: "El perfil ha sido actualizado."
        },
        gender:{
          placeholder:'Your are',
          mr:'Lecteur',
          ms:'Lectrice',
        }
    },
    image_picker:{
      title:'Foto de perfil',
      cancelButtonTitle: 'Anular',
      takePhotoButtonTitle: 'Tomar una fotografía',
      chooseFromLibraryButtonTitle: 'Elige una foto',
      permissionDenied: {
        title: 'Permiso denegado',
        text: 'Para tomar fotografías o seleccionar imágenes de su biblioteca.',
        reTryTitle: 'Reintentar',
        okTitle: "Ok",
      },
      customButtons:{
        removeAvatar: 'Eliminar foto',
      },
    },
    change_password:{
      title: 'Cambiar contraseña',
    },
    "layout": {
        "logout": "Salir",
        "login": "Conexión",
        "register": "Inscripción",
        "forgot_password": "Contraseña olvidada ?",
        "forgot_code": "¿Olvidó su contraseña?",
        "write_us": "¡Escríbanos!",
        "recommend_app": "Recomienda {{app}} a tus amigos",
        "logged_in_as": "Conectado como %username%"
    },
    form: {
        firstname: "Nombre",
        lastname: "Appellido",
        code_licence: "Código / Licencia",
        username: "Nombre del usuario",
        email: "Dirección electrónico",
        current_password: "Contraseña actual",
        password: "Contraseña",
        password_confirmation: "Repita la contraseña",
        new_password: "Nueva contraseña",
        new_password_confirmation: "Repita la contraseña",
        subject: "Sujeto",
        body: "Mensaje",
        errors: {
            email_can_not_be_blank: "Su correo electrónico no puede estar vacío",
            code_can_not_be_blank: "Su código no debe estar vacío",
            firstname_can_not_be_blank: "Su primer nombre no debe estar vacío",
            lastname_can_not_be_blank: "Su apellido no debe estar vacío",
            password_can_not_be_blank: "Introduzca su contraseña",
            password_confirmation_can_not_be_blank: "Confirme su contraseña",
            password_mismatch: "Las dos contraseñas no son idénticas",
            kiosk_can_not_be_blank: "Seleccione un stand",

        }
    },
    errors:{
      upload_avatar: "Se ha producido un problema al cargar tu avatar.",
    }

};
