import React, { Component } from 'react';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import Helpers from '../../helpers';
import ReactDOM from 'react-dom';
import {Loader} from 'react-loaders';

import Api from '../../api';
import Translator from '../../translations';

class  Publication extends Component {

  overlay(){
    return (
      <div style={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 9999,
        backgroundColor: 'rgba(0,0,0,0.7)',
      }}>
      <div style={{
        marginTop: 0,
        marginBottom: 0,
        height: '100vh',
        display: 'flex',
        WebkitBoxOrient: 'vertical',
        WebkitBoxDirection: 'normal',
        WebkitFlexDirection: 'column',
        msFlexDirection: 'column',
        flexDirection: 'column',
        WebkitBoxPack: 'center',
        WebkitJustifyContent: 'center',
        msFlexPack: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}>
        <Loader type="ball-clip-rotate" size='lg' color={"#FFF"} styles={{width:45, height:45,}}/>
      </div>
      </div>
    )
  }
  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  _addNotification(event, message, type='success') {
    event && event.preventDefault();
    this._notificationSystem && this._notificationSystem.addNotification({
      message: message || 'Notification message',
      level: type || 'success',
    });
  }
  generateToken (publication, algorithm='HS256',  pwd='616161') {

     let apiKey = this.props.apiKey;

    return Helpers.generateReaderToken(publication, apiKey, algorithm, pwd);}

  toggleFavorites = (event) =>{
    event && event.stopPropagation();

    const {apiKey, publication} = this.props;

    if (apiKey && publication.publication_number){
      //remove from favorites
      if (publication.publication_number.favorite){
        Api.removeFromFavorites(apiKey,publication.publication_number.id)
            .then((resp) =>{

              this._addNotification(null, resp.message, resp.type)
              publication.publication_number.favorite = !publication.publication_number.favorite || false;
              this.props.onRemoveFromFavorites && this.props.onRemoveFromFavorites (publication);
            })
            .catch((error) =>{
              this._addNotification(null, error.message, error.type|| 'error')
            })
      }else{
        //add to favorites
        Api.addToFavorites (apiKey,publication.publication_number.id)
            .then((resp) =>{

              publication.publication_number.favorite = !publication.publication_number.favorite || true;
              this.props.onAddToFavorites &&   this.props.onAddToFavorites (publication);
              this._addNotification(null, resp.message, resp.type)

            })
            .catch((error) =>{

              this._addNotification(null, error.message, error.type|| 'error')

            })
      }
    }

  }

  view = (event) =>{
    event && event.stopPropagation();

    const { publication, canRead} = this.props;
    const kioskId = process.env.REACT_APP_KIOSK_ID;

    if (canRead){
      this.props.onView && this.props.onView(event, publication);
        
      let rPublication = publication.publication || publication;

      const params = {
        apiUrl: process.env.REACT_APP_API_URL,
        kiosk: kioskId,
      }
       
      const readerUrl = process.env.REACT_APP_READER_URL;
      const publicationReaderUrl = Helpers.getPublicationReaderUrl (rPublication,this.props.apiKey, readerUrl, params);
      window.location.href = publicationReaderUrl;

    }else{
       
      this.props.canNotRead && this.props.canNotRead(publication);
    }

  }

  tagOjd = () =>{
    const { publication} = this.props;

    if (  publication.publication_number &&  publication.publication_number.ojd  &&
      publication.publication_number.ojd.tag && publication.publication_number.ojd.parution_date){
      //   console.log("tag ", publication.name);
      //   Api.tagOjd(  publication.publication_number.ojd.tag,   publication.publication_number.ojd.parution_date)
      //       .then((response) =>{
      //         this.props.onTagOjd &&   this.props.onTagOjd(publication);
      //       })
      //       .catch(()=>{console.log("tag failed")})
    }

  }

  render() {

    const {publication, authMode,  } = this.props;

    if (!publication){
      return (
        <div className="product-items row">
          <h1 className="text-center">{Translator.t('loading')}</h1>
        </div>
      )
    }
    if (!publication.publication_number){
       return null;
    }

    let favoriteClassName = 'btn';

    if (publication.publication_number.favorite){
       favoriteClassName +=' active';
    }
    
    return (
      <div  onMouseEnter={this.tagOjd} onClick={this.view} key={"publication-"+ publication} className="product-item  col-xs-6 col-sm-3 col-md-2 col-lg-2">
          <div className="product-item-opt-2">
              <div className="product-item-info">
                  <div className="product-item-photo">
                      <a  className="product-item-img">
                        <img className="img img-responsive"
                            alt={publication.name || 'Titre'}
                            src={publication.publication_number.directory + "cover.jpg"}/>
                      </a>
                      {/* {
                        authMode &&
                          <div className="product-item-actions">
                              <a onClick={this.toggleFavorites} className={favoriteClassName}><i className="fa fa-book fa-2x"/><span>wishlist</span></a>
                              <a onClick={this.view} className="btn"><i className="fa fa-eye fa-2x"/><span>quickview</span></a>

                              {
                                // <a onClick={this.toggleFavorites} className={favoriteClassName}><span>wishlist</span></a>
                                // <a onClick={this.view} className="btn btn-read"><span>quickview</span></a>
                                // <a onClick={this.view} className="btn btn-quickview"><span>quickview</span></a>
                                // <a onClick={this.view} className="btn btn-quickview1"><i className="fa fa-eye fa-2x"/><span>quickview</span></a>
                              }
                          </div>
                      } */}

                  </div>

              </div>
          </div>
          <NotificationSystem ref="notificationSystem" style={{
            Containers: { DefaultStyle: { zIndex: 9999}}
          }}  />

      </div>
    );
  }
}

Publication.defaultProps = {
  publication: null,
  authMode: true,
  canRead: true,
}

const mapStateToProps = state => ({
  apiKey: state.security.user  ? state.security.user.apiKey : null ,
});

export default connect(mapStateToProps)(Publication);
