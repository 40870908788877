import React, { Component } from 'react';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import ForgotPasswordOrCode from './ForgotPasswordOrCode';

import { connect } from 'react-redux';
import { loginByIp, login } from '../../actions/security';
import Translator from '../../translations';

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loginError: null,
      registerErrors: [],
      registerUser: null,
      loginUser: null,
      fetching: false,
    }
  }

  onRegister = (registerUser, response) => {
    // setTimeout(()=>{
    //   this.redirectToReferOr();
    // },1500);
  }

  redirectToReferOr(pathname = '/publications') {
    const locationState = this.props.location.state;
    if (locationState) {
      const referer = locationState.from;
      this.props.history.push(referer);
    } else {
      this.props.history.push(pathname);
    }
  }

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let modelName = e.target.dataset.model || null;

    let model = null;
    if (modelName) {
      model = { ...this.state[modelName] };
    }
    model[name] = value;

    this.setState({ [modelName]: model, });
  }
  onLogged = (user, loginByIp) => {
    this.props.login(user)
    this.props.onLogged && this.props.onLogged(user, loginByIp);
    this.redirectToReferOr();
  }

  renderRegisterForm() {

    const { kioskId, kiosk } = this.props;
    
    // if (!kiosk.isAuthByIp){
    //   return null;
    // }
    const code = this.props.location.query ? this.props.location.query.code : null;

    return (
      <div className="block-form-create">
        <div className="block-title">
          {Translator.t('registration.create_an_account')}
        </div>
        <div className="block-content">
          <p>{Translator.t('registration.fill_fields')}</p>
          <RegisterForm kioskId={kioskId} kiosk={kiosk} regiserByIp={kiosk.register_mode =='ips'} code={code} onRegister={this.onRegister} />
        </div>
      </div>
    )
  }
  render() {

    const {kioskId, kiosk} = this.props;
    const ipRequired = kiosk.ip_required;
    
    if (ipRequired && !kiosk.isAuthByIp ){
      return (
        <div className="alert alert-info flat-alert">
              Pour vous connecter à notre kiosque, assurez-vous d'être connecté à une borne Wifi correspondante.
            </div>
      )
    }

    return (
      <div className="container-fluid margin-top-40">
      <div className="page-title-base container">
        <h1 className="title-base">{Translator.t('layout.authentication')}</h1>
      </div>
      <div className="container">
        <div className="block-form-login">
          {this.renderRegisterForm()}
          {/* <div className="block-form-registered">

            <div className="block-title">
              {Translator.t('login.already_registered')}
            </div>
            <div className="block-content">
              <p>{Translator.t('login.fill_fields')}</p>
              <LoginForm kioskId={kioskId} loginByIp={kiosk.login_mode =='ips'} onLogged={this.onLogged} />
            </div>

          </div> */}

        </div>
  
        <div className="block-forgot-pass">
          <div className="row">
            <div className="col-md-offset-4 col-md-4 col-md-offset-4">
              <ForgotPasswordOrCode kioskId={kioskId} btnText={Translator.t('layout.forgot_password')} />
            </div>
          </div>
        </div>
      </div>

    </div> 
    );
  }
}


function bindAction(dispatch) {
  return {
    login: user => dispatch(login(user)),
    loginByIp: apiKey => dispatch(loginByIp(apiKey)),
  };
}

const mapStateToProps = state => ({
  user: state.security.user,
  kiosk: state.appConfig.kiosk,
  kioskId: process.env.REACT_APP_KIOSK_ID,
});

export default connect(mapStateToProps, bindAction)(Login);
