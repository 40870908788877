import React, { Component } from 'react';

import { Loader } from 'react-loaders';
import Api from '../../api';
import Translator from '../../translations';

export default class ChangePasswordForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      messageStyle: 'info',
      message: null,
      changePassword: {
        currentPassword: null,
        newPassword: null,
        confirmNewPassword: null,
      },
      fetching: false,
    }
  }
  changePassword = () => {
    const { changePassword } = this.state;
    const { apiKey } = this.props;

    this.setState({ fetching: true });

    let requestData = {
      ...changePassword,
    }
    Api.changePassword(apiKey, JSON.stringify(requestData))
      .then((response) => {
        this.setState({
          message: response.message || response,
          messageStyle: response.type || 'success',
          fetching: false,
        });
        this.props.onChangePassword && this.props.onChangePassword(response);

      })
      .catch((error) => {
        this.setState({
          message: error.message || error,
          messageStyle: 'danger',
          fetching: false
        });
      });
  }
  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let modelName = e.target.dataset.model || null;

    let model = null;
    if (modelName) {
      model = { ...this.state[modelName] };
    }
    model[name] = value;

    this.setState({ [modelName]: model, });
  }

  render() {
    const { messageStyle, message, fetching } = this.state;

    return (
      <div className='row'>
        {
          message &&
          <div className={`flat text-center alert ${messageStyle || 'warning'}`}>
            {message}
          </div>
        }

        {
          <form className="text-center">
            <div className="form-group">
              <input type="password" disabled={fetching} data-model="changePassword" name="currentPassword" onChange={this.handleInput} className="form-control" placeholder={Translator.t('form.current_password')} />
            </div>

            <div className="form-group">
              <input type="password" disabled={fetching} data-model="changePassword" name="newPassword" onChange={this.handleInput} className="form-control" placeholder={Translator.t('form.password')} />
            </div>
            <div className="form-group">
              <input type="password" disabled={fetching} data-model="changePassword" name="confirmNewPassword" onChange={this.handleInput} className="form-control" placeholder={Translator.t('form.password_confirmation')} />
            </div>
            {fetching &&
              <div className="margin-top-40 margin-bottom-40 ">
                <Loader type="ball-pulse-rise" size='lg' active={fetching} styles={{ width: 45, height: 45, }} />
              </div>
            }
            {
              !fetching &&
              <button type="submit" disabled={fetching} onClick={this.changePassword} className="btn btn-inline">{Translator.t('actions.save')}</button>
            }
          </form>
        }
      </div>

    );
  }
}
