import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Translator from '../translations';

export default class Footer extends Component {
  render() {
    return (
            <footer className="site-footer footer-opt-2">

                  <div className="container">
                      <div className="footer-column">

                      <div className="row">
                          <div className="col-md-4 col-sm-5">
                              <strong className="logo-footer">
                                  <a href=""><img src='images/logo_kiosque-by-propress.png' alt="Digital kiosk by ProPress"/></a>
                              </strong>
                              <div className="block-social">
                                  <div className="block-content">
                                      <a target="_blank"  rel="noopener noreferrer" href="https://www.facebook.com/Agence-ProPress-Conseil-1372481299509640/?ref=bookmarks">
                                        <i className="fa fa-facebook" aria-hidden="true"/>
                                      </a>
                                      <a target="_blank"  rel="noopener noreferrer" href="https://www.linkedin.com/company-beta/10984535/">
                                        <i className="fa fa-linkedin" aria-hidden="true"/>
                                      </a>
                                  </div>
                              </div>
                              <p><a href="http://www.propress.fr">ProPress Conseil</a> © 2016.</p>
                          </div>
                          <div className="col-md-4 col-sm-4">
                              <div className="links">
                              <h4 className="title">{Translator.t('write_us')}</h4>
                              <ul>
                                 <li><a href="">15 rue Claude Tillier, 75012 Paris</a></li>
                                 <li><a href="">+33 (0)1 53 27 34 00</a></li>
                                 <li><a href="">contact[@]propress.fr</a></li>
                             </ul>
                              </div>
                          </div>
                          <div className="col-md-4 col-sm-3">
                              <h4>Information</h4>
                              <ul className="links">
                                 <li><a href="https://cgu.kioskpress.fr/">{Translator.t("layout.legal_notices")}</a></li>
                                 <li><Link to="/contact">{Translator.t('contact_us')}</Link></li>
                             </ul>

                          </div>
                      </div>
                      </div>
                  </div>
              </footer>
    );
  }
}
