import JWT from 'jsonwebtoken';

export function  generateReaderToken (publication, apiKey, extraParams = {}, algorithm='HS256',  pwd='616161') {

     let userData = {
       apiKey:apiKey,
       api_key:apiKey,
       kiosk:-1,
       ...extraParams,
     }

     let oHeader = {algorithm,};
     let oPayload = {
       publication,
       userData,
       apiUrl:'https:/prod.kioskp.com/api',
       ...extraParams,
     };

     return JWT.sign(oPayload,pwd,oHeader);
}

export function  getPublicationReaderUrl (publication,apiKey, readerUrl, extraParams={}) {

  const jwt = generateReaderToken(publication,apiKey, extraParams);

  return  readerUrl + jwt;
}

const PublicationHelper = {
  generateReaderToken,
  getPublicationReaderUrl,
}
export default PublicationHelper;
