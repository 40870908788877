// export const  API_URL = "http://dev.kioskp.com/app_dev.php/api";
import Translator  from '../translations';

// export const API_URL = "https://dev.kioskp.com/api";
export const API_URL = process.env.REACT_APP_API_URL;
export const FREE_SECRET_API_KEY = process.env.REACT_APP_API_SECRETE;

function contact(parameters) {
    return fetch(API_URL+'/contact',{
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            locale: Translator.locale,
        },
        body: parameters
    })
        .then(checkStatus)
}

function getRequestUserInfo() {

    return fetch(API_URL+'/request_data/' , {
                accept: 'application/json',
                locale: Translator.locale,
            })
            .then(checkStatus)
 }

function getKioskIps(kioskId) {

    return fetch(API_URL+'/kiosk/ips/'+kioskId , {
                accept: 'application/json',
                locale: Translator.locale,
            })
            .then(checkStatus)
 }

function getKiosk( apiKey) {

    return fetch(API_URL+'/kiosk/info' , {
                accept: 'application/json',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  locale: Translator.locale,
                  Authorization:apiKey,
                },
            })
            .then(checkStatus)
 }

function isClientIpIsAvailable(kioskId) {

    return fetch(API_URL+'/kiosk/client_ip_is_available/'+kioskId , {
                accept: 'application/json',
                locale: Translator.locale,
            })
            .then(checkStatus)
 }

function loadKiosks(apiKey, cb) {
    let finalApiKey = apiKey || FREE_SECRET_API_KEY;

    return fetch(API_URL+'/kiosk/apps', {
                accept: 'application/json',
                locale: Translator.locale,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  locale: Translator.locale,
                  Authorization:finalApiKey,
                },
            })
            .then(checkStatus)
            .then(cb);
}

function getPublicationSubscription(publicationId) {

     return fetch(API_URL+'/subscription/publication/'+publicationId, {
                accept: 'application/json',
                locale: Translator.locale,
            })
            .then(checkStatus);
}

export async function checkStatus(response) {

    if (response.status >= 200 && response.status < 300) {
        return await response.json();
    } else {
        throw await response.json();
    }
}

export default {
  API_URL,
  FREE_SECRET_API_KEY,
  contact,
  loadKiosks,
  checkStatus,
  getRequestUserInfo,
  getKioskIps,
  isClientIpIsAvailable,
  getKiosk,
  getPublicationSubscription,
};
