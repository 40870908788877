import React, { Component } from 'react';

const $ = window.$;
export default class Slider extends Component {

   componentDidMount (){
     let el = $(this.owlCarousel);

     var config = $(el).data();
     config.navText = ['',''];
     config.smartSpeed="800";

     if($(el).hasClass('dotsData')){
         config.dotsData="true";
     }

     if($(el).parents("html").hasClass('cms-rtl')){
         config.rtl="true";
     }
     $(el).owlCarousel(config);
   }
   componentDidUpdate (){
     let el = $(this.owlCarousel);

     var config = $(el).data();
     config.navText = ['',''];
     config.smartSpeed="800";

     if($(el).hasClass('dotsData')){
         config.dotsData="true";
     }

     if($(el).parents("html").hasClass('cms-rtl')){
         config.rtl="true";
     }
     $(el).owlCarousel(config);
   }

   render(){
     return (
       <div className={this.props.className}>
         <div >
               <div ref={(ref) => this.owlCarousel = ref} className="owl-carousel owl-theme "
                   data-nav="true"
                   data-dots="false"
                   data-margin="0"
                   data-items='1'
                   data-autoplaytimeout="700"
                   data-autoplay="true"
                   data-loop="true">
                   {this.props.children}
               </div>
           </div>
       </div>
     )
   }
}
Slider.defaultProps ={
  className:'container',
}
export class SliderItem extends Component {

  render(){
    const {url, imageSrouce, className, imgProps, ...rest} = this.props;

    return (
      <div className={'item '+ className} {...rest}>
          <a href={url} target='_blank'><img src={imageSrouce} alt="Images" {...imgProps} style={{margin:'auto'}}/></a>
      </div>
    )
  }
}
SliderItem.defaultProps = {
  url: '',
  imageSrouce: '',
  className: '',
}
