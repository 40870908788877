export const fr = {
    kiosk: "Kiosque",
    library: "Bibliothèque",
    empty_library: "Votre bibliothèque est vide",
    search_placeholder: "Rechercher...",
    settings: "Paramètres",
    write_us:'Ecrivez-nous !',
    rate_us: "Notez-nous dans {{store}}",
    recommend_to_friend: "Recommandez à un ami",
    account: "Mon compte",
    about: "À propos",
    not_connected: "Vous n'êtes pas connecté",
    welcome_to: "Bienvenue sur",
    cgu: "CGU",
    send: "Envoyer",
    log_out: 'Se déconnecter',
    loading: '',
    contact_us:'Ecrivez-nous !',
    order_by:'Trier par',
    disconnection: 'Déconnexion',
    please_wait: 'Veuillez patienter',
    processing: 'Traitement',
    no_internet_access: "Vous n'avez plus accès à internet",
    continue_without_account: "Continuer sans compte",
    alert_log_out: {
        title:'Déconnexion',
        message:'Êtes-vous sûre de vouloir vous déconnecter ?',
        actions:{
          log_out: 'Se déconnecter',
          cancel: 'Annuler',
        }
    },
    alert_remove_pub_library:{
      title: 'Confirmation de suppression',
      message:{
        one:'Êtes-vous sûre de bien vouloir supprimer le magazine séléctionné ? ',
        other:'Êtes-vous sûre de bien vouloir supprimer les magazines sélectionnés ? ',
      },
      actions:{
        cancel: 'Annuler',
        delete: 'Supprimer'
      }
    },
    actions:{
        cancel: "Annuler",
        show: "Voir",
        read: "Consulter",
        delete: "Supprimer",
        sign_in: "Se connecter",
        sign_out: "S'inscrire",
        download: "Télécharger",
        share: "Partager",
        rate_app: "Noter l'application",
        validate: "Valider",
        edit_avatar: 'Modifier votre avatar',
        change_password: 'Change my password',
        refresh: 'Actualiser',
        save: 'Enregistrer',
    },
    alert_sign_out:{
      title: 'Sign out',
      message: 'Are you sure you want to sign out?',
    },
    "security": {
        "login": {
            "username": "Nom d'utilisateur",
            "email": "Email",
            "password": "Mot de passe",
            "remember_me": "Se souvenir de moi",
            "submit": "Connexion"
        }
    },
    profile: {
        profile: "Profil",
        library: "Bibliothèque",
        add_avatar: 'Ajouter un avatar',
        edit_avatar: 'Modifier mon avatar',
        add_another_account: 'Ajouter un compte',
        my_personnals_data: "Mes informations personnelles",
        current_kiosk: "Vous êtes actuellement connecté(e) sur",
        switch_kiosk_instruction: "Pour changer de compte cliquer sur le kiosque (en noir)",
        change_password: "Modifier le mot de passe",
        edit: {
            submit: "Mettre à jour"
        },
        flash: {
            updated: "Votre compte a été mis à jour"
        },
        gender:{
          placeholder:'Vous êtes ?',
          mr:'Lecteur',
          ms:'Lectrice',
        }
    },
    image_picker:{
      title:'Photo de profil',
      cancelButtonTitle: 'Annuler',
      takePhotoButtonTitle: 'Prendre une photo',
      chooseFromLibraryButtonTitle: 'Choisir une photo',
      permissionDenied: {
        title: 'Permission denied',
        text: 'Pour pouvoir prendre des photos ou choisir des images de votre bibliothèque.',
        reTryTitle: 'Réessayer',
        okTitle: "Ok",
      },
      customButtons:{
        removeAvatar: 'Supprimer la photo',
      },
    },
    change_password:{
      title: 'Changement de mot de passe',
    },
    "registration": {
        'create_an_account': "Je crée mon compte!",
        "fill_fields": "Veuillez renseigner les champs ci-dessous pour vous inscrire",
        "check_email": "Un e-mail a été envoyé à l'adresse %email%. Il contient un lien d'activation sur lequel il vous faudra cliquer afin d'activer votre compte.",
        "confirmed": "Félicitations %username%, votre compte est maintenant activé.",
        "back": "Retour à la page d'origine.",
        "submit": "Créer un compte",
        "flash": {
            "user_created": "L'utilisateur a été créé avec succès."
        },
        "email": {
            "subject": "Bienvenue %username% !",
            "message": "Bonjour %username% !\n\nPour valider votre compte utilisateur, merci de vous rendre sur %confirmationUrl%\n\nCe lien ne peut être utilisé qu'une seule fois pour valider votre compte.\n\nCordialement,\nL'équipe\n"
        }
    },
    login:{
      'already_registered': "Vous avez déjà un compte ?",
      'fill_fields': "Saisissez votre e-mail et mot de passe pour vous connecter",
    },
    "resetting": {
        "check_email": "Un e-mail a été envoyé. Il contient un lien sur lequel il vous faudra cliquer pour réinitialiser votre mot de passe.\nRemarque : Vous ne pouvez demander un nouveau mot de passe que toutes les %tokenLifetime% heures.\n\nSi vous ne recevez pas d'email, vérifiez votre dossier spam ou essayez à nouveau.\n",
        "request": {
            "username": "Nom d'utilisateur ou adresse e-mail",
            "submit": "Réinitialiser le mot de passe"
        },
        "reset": {
            "submit": "Modifier le mot de passe"
        },
        "flash": {
            "success": "Le mot de passe a été réinitialisé avec succès."
        },
        "email": {
            "subject": "Réinitialisation de votre mot de passe",
            "message": "Bonjour %username% !\n\nPour réinitialiser votre mot de passe, merci de vous rendre sur %confirmationUrl%\n\nCordialement,\nL'équipe\n"
        }
    },
    "layout": {
        my_library: "Ma bibliothèque",
        "logout": "Déconnexion",
        "login": "Connexion",
        "register": "Inscription",
        "forgot_password": "Mot de passe oublié ?",
        "forgot_code": "Code oublié ?",
        "write_us": "Ecrivez-nous !",
        "recommend_app": "Recommandez {{app}} à vos amis",
        "logged_in_as": "Connecté en tant que %username%",
        "authentication": "Inscription",
        "legal_notices": "Mentions légales",
    },
    form: {
        firstname: "Prénom",
        lastname: "Nom",
        code_licence: "Code/Licence",
        code: "Code",
        username: "Nom d'utilisateur",
        email: "Adresse e-mail",
        current_password: "Mot de passe actuel",
        password: "Mot de passe",
        password_confirmation: "Répéter le mot de passe",
        new_password: "Nouveau mot de passe",
        new_password_confirmation: "Répéter le nouveau mot de passe",
        subject: "Sujet",
        body: "Message",
        phone: "Numéro de téléphone",
        accept_cgu: "En m'inscrivant j'accepte les CGV/CGU.",
        errors: {
            email_can_not_be_blank: "Votre email ne doit pas être vide",
            code_can_not_be_blank: "Votre code ne doit pas être vide",
            can_not_be_null: "Cette valeur ne doit pas être nulle.",
            firstname_can_not_be_blank: "Votre prénom ne doit pas être vide",
            lastname_can_not_be_blank: "Votre nom ne doit pas être vide",
            password_can_not_be_blank: "Veuillez renseigner votre mot de passe",
            password_confirmation_can_not_be_blank: "Veuillez confirmer votre mot de passe",
            password_mismatch: " Les deux mots de passe ne sont pas identiques",
            kiosk_can_not_be_blank: "Veuillez sélectionner un kiosque",
            cgu_not_accepted: "Vous devez accepter les CGV/CGU pour vous inscrire",

        }
    },
    errors:{
      upload_avatar: "Un problème est survenu lors de l'upload de votre avatar merci de reessayer utltérieurement",
    },
    mentions:{
      "mentions": "Mentions légales",
      "mentions_detail1": "Le présent site est la propriété exclusive de",
      "mentions_detail2": "dont le siège social est situé au",
      "mentions_detail3": "Le directeur de publication est ",

      "hosting": "Hébergement",
      "hosting_detail1": "OVH RCS Roubaix – Tourcoing 424 761 419 00011 ",
      "hosting_detail2": "Code APE 6202A",
      "hosting_detail3": "N° TVA : FR 22 424 761 419",
      "hosting_detail4": "Siège social : 2 rue Kellermann, 59100 Roubaix (France).",


      "cookies_detail1": "L’utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer automatiquement et être conservé temporairement en mémoire ou sur son disque dur. ",
      "cookies_detail2": "Un cookie est un élément qui ne permet pas d’identifier l’utilisateur mais sert à enregistrer des informations relatives à la navigation de celui-ci sur le site internet. Les utilisateurs du site reconnaissent avoir été informés de cette pratiquer. ",
      "cookies_detail3": "Ils pourront désactiver ce cookie par l’intermédiaire des paramètres figurant au sein de leur navigateur web (Google Chrome, Firefox, Internet Explorer, Safari…). ",
      "cookies_detail4": "iPhone© et iPad© sont des marques de Apple Inc ",
      "cookies_detail5": "Android© est une marque de Google Inc  ",


      "privateData": "Données personnelles / Vie privée",
      "privateData_detail1": "En conformité avec les dispositions de la loi du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, vous disposez d’un droit d’accès et de rectification portant sur les données vous concernant en écrivant au siège social. ",
      "privateData_detail2": "Le Service Propress Conseil a fait l’objet d’une déclaration préalable auprès de la Commission Nationale Informatique et Libertés (CNIL). ",
      "privateData_detail3": "L’Utilisateur du Service kiosque-numerique est informé que PROPRESS CONSEIL peut être amené à communiquer ses données personnelles, dans les conditions prévues par la loi, à une autorité judiciaire ou administrative dûment habilitée ",


      "cgvRights": "Droits de propriété intellectuelle :",
      "cgvRights_detail1": "La reproduction ou représentation, intégrale ou partielle, des pages, des données et de toute autre élément constitutif au site, par quelque procédé ou support que ce soit, est interdite et constitue sans autorisation expresse et préalable de l’éditeur une contrefaçon sanctionnée par les articles L335-2 et suivants du Code de Propriété Intellectuelle. ",
      "cgvRights_detail2": "Toute utilisation du contenu et de site à des fins illégales fera l’objet de poursuites judiciaires à l’égard des contrevenants.",

      "readBook": "Lire",
      "offers": "Titres du moment",

      "newsletter-title": "Inscrivez à la newsletter",
      "newsletter-error": "Veuillez entrer un email valide",
      "newsletter-success": "Inscription effectuée avec succès, nous vous remercions de votre soutien",
      "Notre selection presse française": "Notre selection presse française",
      "Notre selection presse anglaise": "Notre selection presse anglaise"
    }

};
