import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Loader } from 'react-loaders';
import _ from 'lodash';
import Api from '../../api';

class ChangeCode extends Component {

  constructor(props) {
    super(props);
    this.state = {
      messageStyle: 'info',
      message: null,
      changeCode: {
        currentCode: null,
        newCode: null,
        codeConfirmation: null,
      },
      fetching: false,
    }
  }
  changeCode = () => {
    const { changeCode } = this.state;
    const { apiKey } = this.props;

    if (_.isEmpty(changeCode.currentCode)) {
      this.setState({
        message: 'Current code can not be null',
        messageStyle: 'danger',
        fetching: false
      });
    }
    else if (_.isEmpty(changeCode.newCode)) {
      this.setState({
        message: 'New code can not be null',
        messageStyle: 'danger',
        fetching: false
      });
    }
    else if (changeCode.newCode.length < 6) {
      this.setState({
        message: 'Code lenght must greater or equal 6 characters',
        messageStyle: 'danger',
        fetching: false
      })

    } else if (_.isEmpty(changeCode.codeConfirmation)) {
      this.setState({
        message: 'Please confirm your new code',
        messageStyle: 'danger',
        fetching: false
      })

    } else if (changeCode.newCode !== changeCode.codeConfirmation) {
      this.setState({
        message: 'Codes do not match',
        messageStyle: 'danger',
        fetching: false
      })
    } else if (changeCode.newCode === changeCode.currentCode) {
      this.setState({
        message: 'New code must be diffent with the current code',
        messageStyle: 'danger',
        fetching: false
      })

    } else {
      this.setState({ fetching: true });

      let requestData = {
        ...changeCode,
      }
      Api.changeCode(apiKey, JSON.stringify(requestData))
        .then((response) => {
          this.setState({
            message: response.message || response,
            messageStyle: response.type || 'success',
            fetching: false,
          });
          this.props.onChangeCode && this.props.onChangeCode(response);
          setTimeout(() => {
            this.props.history && this.props.history.push('/profile');
          }, 2500)
        })
        .catch((error) => {
          this.setState({
            message: error.message || error,
            messageStyle: 'danger',
            fetching: false
          });
        });
    }
  }
  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let modelName = e.target.dataset.model || null;

    let model = null;
    if (modelName) {
      model = { ...this.state[modelName] };
    }
    model[name] = value;

    this.setState({ [modelName]: model, });
  }

  render() {
    const { messageStyle, message, fetching } = this.state;

    return (
      <div className="container-fluid margin-top-40 margin-bottom-40 text-center">
        <div className="page-title-base container">
          <h1 className="title-base">Changing code</h1>
        </div>
        <div className="container-fluid">
          <div className='row'>
            <div className='col-md-offset-4 col-md-4 col-md-offset-4'>
              {
                message &&
                <div className={`flat text-center alert ${messageStyle || 'warning'}`}>
                  {message}
                </div>
              }

              {
                <form >
                  <div className="form-group">
                    <input type="code" disabled={fetching} data-model="changeCode" name="currentCode" onChange={this.handleInput} className="form-control" placeholder="Current code" />
                  </div>

                  <div className="form-group">
                    <input type="code" disabled={fetching} data-model="changeCode" name="newCode" onChange={this.handleInput} className="form-control" placeholder="Code" />
                  </div>
                  <div className="form-group">
                    <input type="code" disabled={fetching} data-model="changeCode" name="codeConfirmation" onChange={this.handleInput} className="form-control" placeholder="Code confirmation" />
                  </div>
                  {fetching &&
                    <div className="margin-top-40 margin-bottom-40 ">
                      <Loader type="ball-pulse-rise" size='lg' active={fetching} styles={{ width: 45, height: 45, }} />
                    </div>
                  }
                  {
                    !fetching &&
                    <button type="submit" disabled={fetching} onClick={this.changeCode} className="btn btn-inline">Change code</button>
                  }
                </form>
              }
            </div>
          </div>

        </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  apiKey: state.security.user ? state.security.user.apiKey : null,
});

export default connect(mapStateToProps)(ChangeCode);
