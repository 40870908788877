import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { switchLocale } from "../actions/appConfig";
import { logout } from "../actions/security";
import Translator from "../translations";
import Modal from "react-awesome-modal";
import { Loader } from "react-loaders";
import _ from "lodash";

const $ = window.$;

class Header extends Component {
  constructor(props) {
    super(props);

    let search = props.history.location.search.split("?q=");
    search = search.length ? search[1] : "";
    this.state = {
      search,
      logginout: false,
      showLogoutModal: false,
    };
  }

  componentDidMount() {
    if (this.textInput) {
      this.textInput && this.textInput.focus();
      var temp_value = this.textInput.value;
      this.textInput.value = "";
      this.textInput.value = temp_value;
    }
    $(".mid-header").sticky({ topSpacing: 0 });
  }

  isActivePathname(pathname) {
    return this.props.location && this.props.location.pathname === pathname;
  }

  getMenuItemClass(pathname) {
    let className = this.isActivePathname(pathname) ? "active" : "";
    return className;
  }

  onChange = (e) => {
    let search = e.target.value;

    this.setState({
      [e.target.name]: search,
    });
    this.doSearch(search);
  };

  search = (e) => {
    let search = this.state.search;
    this.doSearch(search);
  };

  doSearch(search) {
    if (!this.props.onSearchBarTextChanged) {
      this.props.history &&
        this.props.history.replace &&
        this.props.history.push({
          pathname: this.props.location.pathname,
          search: "?q=" + search,
        });
    } else {
      this.props.onSearchBarTextChanged &&
        this.props.onSearchBarTextChanged(search);
    }
  }
  logout = () => {
    this.setState({ logginout: true });
     this.props.logout(this.props.apiKey);
    this.props.history.push("/");
  };
  closeLogoutModal = () => {
    this.setState({ showLogoutModal: false });
  };

  openLogoutModal = (event) => {
    event && event.preventDefault();
    this.setState({ showLogoutModal: true });
  };
  renderLogoutModal() {
    return (
      <Modal visible={this.state.showLogoutModal} size="lg" className={" flat"}>
        <div className="modal-header">
          <h3>{Translator.t("alert_log_out.title")}</h3>
        </div>
        <div className="text-center modal-body">
          <div style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}>
            {!this.state.logginout && (
              <h4>{Translator.t("alert_log_out.message")}</h4>
            )}

            {this.state.logginout && (
              <Loader
                type="ball-pulse-rise"
                size="lg"
                styles={{ width: 45, height: 45 }}
              />
            )}
          </div>
        </div>
        {!this.state.logginout && (
          <div className="modal-footer">
            <button
              className="btn btn-success"
              bsstyle="success"
              onClick={this.closeLogoutModal}
              data-dismiss="modal"
            >
              {Translator.t("alert_log_out.actions.cancel")}
            </button>
            <button
              className="btn btn-danger"
              bsstyle="danger"
              onClick={this.logout}
            >
              {Translator.t("alert_log_out.actions.log_out")}
            </button>
          </div>
        )}
      </Modal>
    );
  }
  renderSwitchLocale() {
    const { locale } = this.props;
    const locales = [
      { code: "fr", imageSource: "images/flags/flag_french.png" },
      { code: "en", imageSource: "images/flags/flag_english.png" },
      { code: "es", imageSource: "images/flags/flag_spain.png" },
    ];

    return (
      <div className="switcher  switcher-language">
        <strong className="title">Select language</strong>
        <ul className="switcher-options ">
          {locales.map((localeItem, i) => {
            return (
              <li
                key={localeItem.code}
                className={
                  "switcher-option" +
                  (locale === localeItem.code ? " switcher-active" : "")
                }
              >
                <a onClick={() => this.props.switchLocale(localeItem.code)}>
                  <img
                    className="switcher-flag"
                    alt="flag"
                    src={localeItem.imageSource}
                  />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
  renderHeaderBar() {
    const { isAuth } = this.props;
    const { search } = this.state;

    if (!isAuth) return null;
    return (
      <div className=" header-nav mid-header">
        <div className="container">
          <div className="box-header-nav">
            {isAuth && (
              <div className="block-nav-menu">
                <ul className="ui-menu">
                  <li>
                    <Link
                      className={this.getMenuItemClass("/publications")}
                      to="/publications"
                    >
                      {Translator.t("kiosk")}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={this.getMenuItemClass("/favorites")}
                      to="/favorites"
                    >
                      {Translator.t("layout.my_library")}
                    </Link>
                  </li>
                </ul>
              </div>
            )}

            <span data-action="toggle-nav" className="nav-toggle-menu">
              <span>Menu</span>
              <i aria-hidden="true" className="fa fa-bars"></i>
            </span>

            {/*
                    <div className="block-minicart dropdown ">
                        <a className="dropdown-toggle" href="" role="button" data-toggle="dropdown">
                            <span className="cart-icon"></span>
                        </a>
                        <div className="dropdown-menu">
                            <form>
                                <div  className="minicart-content-wrapper" >
                                    <div className="subtitle">
                                        You have 6 item(s) in your cart
                                    </div>
                                    <div className="minicart-items-wrapper">
                                        <ol className="minicart-items">
                                            <li className="product-item">
                                                <a className="product-item-photo" href="" title="The Name Product">
                                                    <img className="product-image-photo" src="images/media/index1/minicart.jpg" alt="The Name Product"/>
                                                </a>
                                                <div className="product-item-details">
                                                    <strong className="product-item-name">
                                                        <a href="">Burberry Pink &amp; black</a>
                                                    </strong>
                                                    <div className="product-item-qty">
                                                        <span className="label">Quantity:</span ><span className="number">6</span>
                                                    </div>
                                                    <div className="product-item-price">
                                                        <span className="price"> $17.96</span>
                                                    </div>
                                                    <div className="product-item-actions">
                                                        <a className="action delete" href="" title="Remove item">
                                                            <span>Remove</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="product-item">
                                                <a className="product-item-photo" href="" title="The Name Product">
                                                    <img className="product-image-photo" src="images/media/index1/minicart.jpg" alt="The Name Product"/>
                                                </a>
                                                <div className="product-item-details">
                                                    <strong className="product-item-name">
                                                        <a href="">Burberry Pink &amp; black</a>
                                                    </strong>
                                                    <div className="product-item-qty">
                                                        <span className="label">Quantity:</span ><span className="number">6</span>
                                                    </div>
                                                    <div className="product-item-price">
                                                        <span className="price"> $17.96</span>
                                                    </div>
                                                    <div className="product-item-actions">
                                                        <a className="action delete" href="" title="Remove item">
                                                            <span>Remove</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="subtotal">
                                        <span className="label">Total</span>
                                        <span className="price">$630</span>
                                    </div>
                                    <div className="actions">
                                        <a className="btn btn-viewcart" href="">
                                            <span>Shopping bag</span>
                                        </a>
                                        <button className="btn btn-checkout" type="button" title="Check Out">
                                            <span>Check out</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    */}

            <div className="block-search">
              <div className="block-title">
                <span>Search</span>
              </div>
              <div className="block-content">
                <div className="form-search">
                  <form>
                    <div className="box-group">
                      <input
                        type="text"
                        name="search"
                        className="form-control"
                        onChange={this.onChange}
                        value={search}
                        placeholder={Translator.t("search_placeholder")}
                      />
                      <button
                        className="btn btn-search"
                        type="button"
                        onClick={this.search}
                      >
                        <span>search</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    const { isAuth } = this.props;
    const { search } = this.state;
    let kioskLogo =
      process.env.REACT_APP_KIOSK_LOGO || "images/digital-kiosk.png";
    return (
      <header className="site-header header-opt-2">
        <div className="header-top">
          <div className="container">
            <ul className="hotline nav-left">
              <li>
                <span>
                  <i className="fa fa-phone" aria-hidden="true"></i>+ (0)1 53 27
                  34 00{" "}
                </span>
              </li>
              <li>
                <Link to="/contact" style={{ color: "#fff" }}>
                  <span>
                    <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                    {Translator.t("contact_us")}{" "}
                  </span>
                </Link>
              </li>
            </ul>

            <ul className="links nav-right">
              {!isAuth && (
                <li>
                  <Link to="/login">
                    {Translator.t("layout.authentication")}
                  </Link>
                </li>
              )}
              {isAuth && (
                <li>
                  <a href="#logout" onClick={this.openLogoutModal}>
                    {Translator.t("log_out")}
                  </a>{" "}
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="header-content">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <strong className="logo">
                  <Link to="/publications">
                    <img
                      src={kioskLogo}
                      className="img img-fluid logo"
                      alt="logo"
                    />
                  </Link>
                </strong>
                <h3>
                  Nous avons le plaisir de vous offrir la presse en numérique
                </h3>
              </div>
            </div>
          </div>
        </div>
        {/* {this.renderHeaderBar()} */}
        {this.renderLogoutModal()}
      </header>
    );
  }
}

function bindAction(dispatch) {
  return {
    switchLocale: (locale) => dispatch(switchLocale(locale)),
    logout: (apiKey) => dispatch(logout(apiKey)),
  };
}

const mapStateToProps = (state) => ({
  isAuth: !!state.security.user,
  apiKey: state.security.user ? state.security.user.apiKey : null,
  locale: state.appConfig.locale,
});

export default connect(mapStateToProps, bindAction)(Header);
