import React, { Component } from 'react';

import Header from './Header';
 
import './Reader.css'

export default class Reader extends Component {


  render() {


    return (
      <div className="reader">
        <Header/>

      </div>

    );
  }
}
