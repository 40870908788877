import React, { Component } from 'react';
import ProfileForm from './ProfileForm';
import ChangePasswordForm from './ChangePasswordForm';
import Translator from '../../translations';

import { connect } from 'react-redux';

class  Profile extends Component {

  render() {
    const {apiKey,user} = this.props;

    return (
      <div className="container-fluid margin-top-40 ">
          <div className="container">
              <div className="block-form-login">
                  <div className="row">
                      <div className="col-md-offset-3 col-md-6 col-md-offset-3">

                        <ul className="nav nav-pills  nav-justified">
                          <li className="active"><a data-toggle="tab" href="#profile">{Translator.t('profile.profile')}</a></li>
                          <li><a data-toggle="tab" href="#change_pc">{Translator.t('profile.change_password')}</a></li>
                        </ul>

                        <div className="tab-content">
                          <div id="profile" className="tab-pane fade in active">
                            <ProfileForm user={user} apiKey={apiKey} kioskId={process.env.REACT_APP_KIOSK_ID}/>
                          </div>

                          <div id="change_pc" className="tab-pane fade">
                            <ChangePasswordForm  apiKey={apiKey} kioskId={process.env.REACT_APP_KIOSK_ID}/>
                          </div>
                        </div>
                      </div>

                  </div>

              </div>
          </div>
          <div className=" margin-bottom-30"/>
      </div>
    );
  }
}


function bindAction(dispatch) {
    return {
    };
}

const mapStateToProps = state => ({
  user: state.security.user,
  apiKey: state.security.user ? state.security.user.apiKey : null,
});

export default connect(mapStateToProps, bindAction)(Profile);
