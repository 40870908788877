import React, { Component } from 'react';
import _ from 'lodash';
import { Loader } from 'react-loaders';

import Api from '../../api';
import Translator from '../../translations';

const DEFAULT_AVAT_URL = 'images/placeholder.png';

export default class ProfileForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      message: null,
      messageType: 'info',
      avatarPreviewUrl: DEFAULT_AVAT_URL,
      user: props.user || null,
      fetching: false,
    }
  }
  componentDidMount() {
    this.setState({ errors: null, fetching: true, });

    Api.getUserInfo(this.props.apiKey)
      .then((user) => {
        this.setState({ user, fetching: false, })
      })
      .catch((error) => {
        this.setState({ message: error.message || null, messageType: 'danger', fetching: false, })

      })
  }
  getUserProperty(field, defaultValue = '') {
    const { user } = this.state;
    if (!user) return defaultValue;

    return user[field] || defaultValue;
  }

  hasError(field) {
    return this.getError(field) !== undefined;
  }

  getError(field) {
    return _.find(this.state.errors, { 'property_path': field });
  }

  edit = () => {
    this.setState({ errors: null, fetching: true, });

    const { user } = this.state;
    let requestData = {
      ...user,
      kiosk: this.props.kioskId,
      validationGroup: "API",
    }

    Api.profileEdit(this.props.apiKey, JSON.stringify(requestData))
      .then((user) => {

        this.setState({ user: user, fetching: false, message: Translator.t('profile.flash.updated'), messageType: 'success' });
        this.props.onEdit && this.props.onEdit(user);
      })
      .catch((error) => {
        this.setState({
          errors: error.validationErrors || error.message || error,
          fetching: false,
          messageType: error.type || 'danger'
        });
      })
  }

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let modelName = e.target.dataset.model || null;

    let model = null;
    if (modelName) {
      model = { ...this.state[modelName] };
    }
    model[name] = value;
    let file = e.target.files ? e.target.files[0] : null;
    if (file) {
      let reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          avatarPreviewUrl: reader.result,
        });
      }
      reader.readAsDataURL(file);
    }

    this.setState({ [modelName]: model, });
  }

  renderFieldError(field) {
    let error = this.getError(field);
    if (error === undefined) return null;
    return (
      <small className="text-danger">{error.message}</small>
    )
  }

  renderError() {
    const { errors, messageType } = this.state;
    if (!errors || typeof errors !== 'string') return null;
    const className = `alert alert-${messageType} flat`;
    return (
      <div className={className}>{errors}</div>
    )
  }


  render() {
    const { fetching, message, messageType } = this.state;

    return (
      <form style={{ position: 'relative' }} className="text-center">
        {
          /*
          <div className="form-group">
              <div className="row text-center">
                  <img src={avatarPreviewUrl} alt="User avatar" style={{maxWidth:200}} className="img img-responsive"  />
                  <input disabled={fetching} required accept=".png,.jpg,.jpeg" type="file" data-model="user" name="avatar" onChange={this.handleInput} className="form-control" placeholder="Code"/>
                  {this.renderFieldError('avatar')}
              </div>
          </div>
          */
        }
        {
          message &&
          <div className={`flat text-center alert ${messageType || 'warning'}`}>
            {message}
          </div>
        }
        <div>{this.renderError()}</div>

        <div className="form-group">
          <select disabled={fetching} value={this.getUserProperty('gender', '')} required type="text" data-model="user" name="gender" onChange={this.handleInput} className="form-control" >
            <option value='' disabled>{Translator.t('profile.gender.placeholder')}</option>
            <option value='mr'>{Translator.t('profile.gender.mr')}</option>
            <option value='ms'>{Translator.t('profile.gender.ms')}</option>
          </select>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-12">
            <div className="form-group">
              <input disabled={fetching} required type="text" data-model="user" value={this.getUserProperty('firstname')} name="firstname" onChange={this.handleInput} className="form-control" placeholder={Translator.t('form.firstname')} />
              {this.renderFieldError('firstname')}
            </div>
          </div>
          <div className="col-md-6 col-xs-12">
            <div className="form-group">
              <input disabled={fetching} required type="text" data-model="user" value={this.getUserProperty('lastname')} name="lastname" onChange={this.handleInput} className="form-control" placeholder={Translator.t('form.lastname')} />
              {this.renderFieldError('lastname')}
            </div>
          </div>
        </div>
        <div className="form-group">
          <input disabled={fetching} required type="text" data-model="user" name="email" value={this.getUserProperty('email')} onChange={this.handleInput} className="form-control" placeholder={Translator.t('form.email')} />
          {this.renderFieldError('email')}

        </div>

        {
          //   <div className="form-group">
          //     <input disabled={fetching} required type="password" data-model="user" name="code" value={this.getUserProperty('code')}  onChange={this.handleInput} className="form-control" placeholder="Code"/>
          //     {this.renderFieldError('code')}
          // </div>
        }

        <Loader type="ball-beat" active={fetching} />

        {!fetching && <button disabled={fetching} type="submit" onClick={this.edit} className="btn btn-inline">{Translator.t('actions.save')}</button>}
      </form>
    );
  }
}
