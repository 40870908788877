import React, { Component } from 'react';
import {Loader} from 'react-loaders';

import Translator from '../../translations';
import Api from '../../api';

export default class  LoginForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      loginError: null,
      loginUser: null,
      fetching: false,
    }
  }

  login = () =>{
    this.setState({loginError:null,fetching:true,});
    const {kioskId,loginByIp} = this.props;
    const {loginUser} = this.state;
    let loginData = {
      ...loginUser,
      kiosk:kioskId,
    }

    Api.login(JSON.stringify(loginData), false)
        .then((response) =>{
          const user = Object.assign({},{
            // ...response.token.user,
            firstname:response.token.user.firstname || null,
            lastname:response.token.user.lastname || null,
            email:response.token.user.email || null,
            kiosk:this.props.kiosk,
            avatar:response.token.user.avatar ? response.token.user.avatar.web_path : null,
            apiKey:response.api_key,
          });
          user.loggedByIp = loginByIp;
          this.setState({ fetching:false,});

          this.props.onLogged && this.props.onLogged(user,loginByIp);
        })
        .catch((error) =>{
          this.setState({loginError: error.message || error, fetching:false,});
        })
  }

  handleInput = (e) =>{
    const name = e.target.name;
    const value = e.target.value;
    let modelName = e.target.dataset.model || null;

    let model = null;
    if (modelName){
      model =  {...this.state[modelName]};
    }
      model[name] = value;

    this.setState({[modelName]:model,});
  }

  render() {
    const {loginError, fetching, } = this.state;

    return (
      <form  className="text-center">
          {loginError && <div className="alert alert-danger flat-alert">{loginError}</div>}
          <div className="form-group">
              <input disabled={fetching} type="text" data-model="loginUser" name="email" onChange={this.handleInput} className="form-control" placeholder={Translator.t('form.email')}/>
          </div>

          <div className="form-group">
              <input disabled={fetching} type="password" data-model="loginUser" name="password" onChange={this.handleInput} className="form-control" placeholder={Translator.t('form.password')}/>
          </div>
          {
            // <div className="checkbox">
            //     <label>
            //         <input type="checkbox"/><span>Remember me!</span>
            //     </label>
            // </div>
          }
          <Loader type="ball-beat" active={fetching} />

          {!fetching && <button disabled={fetching} type="submit" onClick={this.login} className="btn btn-inline">{Translator.t("security.login.submit")}</button>}

      </form>
    );
  }
}

LoginForm.defaultProps = {
  loginByIp: false,
  kioskId: null,
}
