import React  from 'react';
import {Loader} from 'react-loaders';
import _ from 'lodash';
import { connect } from 'react-redux';

import Api from '../api';
import Translator from '../translations';

export const FormErrors = ({formErrors}) =>
  <div className='formErrors'>
    {Object.keys(formErrors).map((fieldName, i) => {
      if(formErrors[fieldName].length > 0){
        return (
          <p key={i}>{fieldName} {formErrors[fieldName]}</p>
        )
      } else {
        return '';
      }
    })}
  </div>

class Contact extends React.Component {

  state = {
    errors: [],
    message:null,
    messageType:'info',
    fetching: false,
    sent: false,
  };

  handleInput = (e) =>{
    const name = e.target.name;
    const value = e.target.value;
    let modelName = e.target.dataset.model || null;

    let model = null;
    if (modelName){
      model =  {...this.state[modelName]};
    }
      model[name] = value;

    this.setState({[modelName]:model,});
  }

  getError(field){
    let errors = this.state.errors || {};
    let error = errors.children ? errors.children[field]: undefined;
    if (error !== undefined &&  error.errors && error.errors.length > 0){
      error = {message: _.join(error.errors,', ')} ;
    }

    return _.find(errors,{'property_path':field}) || error;
  }
  renderFieldError(field){
    let error = this.getError(field);
    if (error === undefined) return null;
    return (
      <small className="text-danger">{error.message}</small>
    )
  }

  renderError(){
    const {errors,messageType} = this.state;
    if (!errors || typeof errors !== 'string') return null;
    const className = `alert alert-${messageType} flat text-center`;
    return (
      <div className={className}>{errors}</div>
    )
  }
  contact = () =>{
    const {contact} = this.state;
    this.setState({ fetching:true, errors:[]});
    let requestData = {
      ...contact,
      kiosk: process.env.REACT_APP_KIOSK_ID,
      validationGroups: ['API'],
    }
    Api.contact(JSON.stringify(requestData))
        .then((response) => {
          this.setState({sent:true, fetching:false,message:'Message envoyé avec succès.', messageType:response.type || 'success'});

          setTimeout(() => {
            const locationState = this.props.location.state;
            if (locationState){
              const referer  = locationState.from;
              this.props.history.push(referer);
            }else{
              this.props.history.push('/publications');
            }
          }, 2000)
        })
        .catch((error) => {
          this.setState({
            errors: error.validationErrors ||  error.errors || error.message || error,
            fetching:false,
            messageType:error.type || 'danger'
          });
        })
  }
   render() {
     const {sent, fetching, message,messageType} = this.state;

      return (
        <div className="container-fluid margin-top-40 ">
            <div className="row">
              <div className="col-md-offset-2 col-md-8 col-md-offset-2">
              <div className="page-title-base ">
                  <h1 className="title-base">{Translator.t('layout.write_us')}</h1>
              </div>
                <form>
                  {
                    message &&
                      <div className={`flat text-center alert alert-${messageType || 'warning'}`}>
                       {message}
                     </div>
                  }
                  {
                    !sent && <div>
                      <div>{this.renderError()}</div>

                      <div className="row">
                        <div className="col-md-6 col-xs-12">
                          <div className="form-group">
                              <input disabled={fetching}  required type="text" data-model="contact"  name="firstname" onChange={this.handleInput} className="form-control" placeholder={Translator.t('form.firstname')}/>
                              {this.renderFieldError('firstname')}

                          </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                          <div className="form-group">
                              <input disabled={fetching} required type="text" data-model="contact" name="lastname" onChange={this.handleInput} className="form-control" placeholder={Translator.t('form.lastname')}/>
                              {this.renderFieldError('lastname')}

                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 col-xs-12">
                          <div className="form-group">
                              <input disabled={fetching} required type="text" data-model="contact" name="email" onChange={this.handleInput} className="form-control" placeholder={Translator.t('form.email')}/>
                              {this.renderFieldError('email')}

                          </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                          <div className="form-group">
                              <input  disabled={fetching} type="text" data-model="contact" name="phone" onChange={this.handleInput} className="form-control" placeholder={Translator.t('form.phone')}/>
                              {this.renderFieldError('phone')}

                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                          <input disabled={fetching} required type="text" data-model="contact" name="subject" onChange={this.handleInput} className="form-control" placeholder={Translator.t('form.subject')}/>
                          {this.renderFieldError('subject')}

                      </div>
                      <div className="form-group">
                          <textarea disabled={fetching} required  rows="5" data-model="contact" name="message" onChange={this.handleInput} className="form-control" placeholder={Translator.t('form.body')}></textarea>
                          {this.renderFieldError('message')}
                      </div>
                      <Loader type="ball-beat" active={fetching} />

                      {!fetching && <button disabled={fetching} type="button" onClick={this.contact} className="btn btn-inline">{Translator.t('send')}</button>}

                    </div>
                  }
                </form>
              </div>
            </div>
            <div className="margin-bottom-40"/>
         </div>
      )
   }
}


const mapStateToProps = state => ({
});

export default connect(mapStateToProps)(Contact);
