import React, { Component } from 'react';
import { connect } from 'react-redux';

import GridPublications from '../common/publications/GridPublications';
import Api from '../api';
import Translator from '../translations';
import _ from 'lodash';

class Favorites extends Component {

  state = {
    loaded:false,
    favorites:[],
    error: null,
  }

  componentDidMount(){
    const {apiKey} = this.props;

    Api.getUserFavorites(apiKey)
        .then((favorites) => {
          this.setState({loaded:true, favorites,error:null})
        }).catch((error) =>{
          this.setState({error: error.message || error, loaded:true,})
        });
  }
  onRemoveFromFavorites = (item) =>{
    let favorites = _.reject(this.state.favorites,{'id': item.id});
      this.setState({   favorites,   })
  }


  render() {

    const {loaded, favorites, error} = this.state;

    return (
      <div className="container margin-top-40 margin-bottom-40">
        <div className="container-fluid">
          {error && <div className="alert alert-danger flat">{error}</div>}

          <GridPublications
                loaded={loaded}
                categeryName={Translator.t('profile.library')}
                onRemoveFromFavorites={this.onRemoveFromFavorites}
                allowPagination={false}
                showToolbarSorter={false}
                publications={favorites} />

        </div>
      </div>

    );
  }
}

function bindAction(dispatch) {
    return {};
}

const mapStateToProps = state => ({
  apiKey: state.security.user  ? state.security.user.apiKey : null ,
  user: state.security.user || null,
  isAuth: !!state.security.user,
});

export default connect(mapStateToProps, bindAction)(Favorites);
