import React, { Component } from 'react';

import Modal from 'react-awesome-modal';

export default class  IpNoAvailableModal extends Component {

  constructor(props){
    super(props);
    this.state = {
    }
  }


  render() {
    const {kioskId, visible, ...props} = this.props;
    return (
      <Modal  show={visible}  effect="fadeInUp" {...props}>
          <div className="row  text-center margin-top-20">
            <div className="col-md-offset-2 col-md-8 col-md-offset-2">
              <p>Vous devez être connecté dans un des salons {process.env.REACT_APP_KIOSK_NAME }</p>
            </div>
          </div>

      </Modal>
    );
  }
}
