import React, { Component } from 'react';

import Publications from './Publications';
import _ from 'lodash';
import Translator from '../../translations';
const SHOW_TOOLBAR_SORTER_OPTIONS = [
  {value: 'position', label:'Position'},
  {value: 'name', label:'Name'},
];

class GridPublications extends Component {

  state = {
    page : 1,
  }
  renderModes(){
    if (!this.props.showModes) return null;
    return (
      <div className="modes">
          <strong  className="label">View as:</strong>
          <strong  className="modes-mode active mode-grid" title="Grid">
              <span>grid</span>
          </strong>
          <a  href="List_Product---Main.html" title="List" className="modes-mode mode-list">
              <span>list</span>
          </a>
      </div>
    )
  }

  renderToolbarSorter(){
    if (!this.props.showToolbarSorter) return null;
    const {showToolbarSorterOptions, categeryColor} = this.props;
    return (
      <div className="toolbar-sorter " >
          <label className="label" style={{color:categeryColor}}>{Translator.t('order_by')}:</label>
          <select className="sorter-options form-control" onChange={(e) => this.props.onOrderChange && this.props.onOrderChange(_.find(showToolbarSorterOptions,{'value':e.target.value}))} defaultValue={"position"} >
              {
                showToolbarSorterOptions.map((item,i) => (
                  <option key={i} value={item.value}>{item.label}</option>
                ))
              }

          </select>
      </div>
    )
  }

  renderToolbarLimiter(){
    if (!this.props.showToolbarLimiter) return null;
    return (
      <div className="toolbar-limiter">
          <label   className="label" style={{color:this.props.categeryColor}}>
              <span>Show:</span>
          </label>

          <select className="limiter-options form-control" defaultValue={"9"}>
              <option value="9">Show 18</option>
              <option value="15">Show 15</option>
              <option value="30">Show 30</option>
          </select>
      </div>
    )
  }

  pagination(){
    const {perPagePublications,allowPagination, publications} = this.props;

    if (!allowPagination) return null;
    const {page} = this.state;
    const totalPage = publications.length/perPagePublications;
    // if (page > totalPage){
    //
    // }
    let pages = _.range(0, totalPage);


    return (
      <ul className="pagination">
          {
            page !== 1 && <li className="action">
                <a href="">
                    <span><i aria-hidden="true" className="fa fa-angle-left"></i></span>
                </a>
            </li>
          }
          {
            pages.map((p,i) =>{
              return (
                <li key={i} className={(p+1) === page ? 'active':''}>
                    <a href="">{p+1}</a>
                </li>
              )
            })
          }

          {
            page !== totalPage &&
            <li className="action">
                <a href="">
                    <span><i aria-hidden="true" className="fa fa-angle-right"></i></span>
                </a>
            </li>
          }

      </ul>
    )
  }

  render() {

    const {categeryName,categeryBgColor,categeryColor,loaded, publications, ...publicationsProps} = this.props;
    return (
      <div className="columns container-fluid">
          <div className="row">
              <div className="col-lg-12 col-md-12  col-main">
                  {/* <div className=" toolbar-products toolbar-top"  style={{backgroundColor:categeryBgColor, color:categeryColor}}>

                      {
                        // <div className="btn-filter-products">
                        //     <span>Filter</span>
                        // </div>
                      }

                      <h1 className="cate-title">{categeryName}</h1>

                      {this.renderModes()}

                      <div className="toolbar-option">
                          {this.renderToolbarSorter()}
                          {this.renderToolbarLimiter()}
                      </div>

                      {this.pagination()}
                  </div> */}

                  <div className="products  products-grid">
                    <Publications {...publicationsProps}  publications={publications} loaded={loaded} />

                  </div>

                  {
                    // <div className=" toolbar-products toolbar-bottom">
                    //     {this.renderModes()}
                    //     <div className="toolbar-option">
                    //         {this.renderToolbarSorter()}
                    //         {this.renderToolbarLimiter()}
                    //     </div>
                    //     {this.pagination()}
                    // </div>
                  }

              </div>

          </div>
      </div>

    );
  }
}

GridPublications.defaultProps = {
  showModes: false,
  loaded: false,
  allowPagination: true,
  perPagePublications: 2,
  publications: [],
  categeryName: '',
  categeryBgColor: 'transparent',
  categeryColor: '#000',
  showToolbarSorter: true,
  showToolbarSorterOptions: SHOW_TOOLBAR_SORTER_OPTIONS,
  showToolbarLimiter: false,
}

export default GridPublications;
