import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';

import './ram.css';

class RAM extends Component {
  
 
  componentDidMount(){
    window.$('body').addClass('ram')
  }
 
  componentWillUnmount(){
    window.$('body').removeClass('ram')
  }
  render() {


    return (
      <div className="margin-top-50 text-center">
          <Link className={"btn btn-access-press "} to="/publications" >Accéder à la presse numérique</Link>
      </div>
    );
  }
}

function bindAction(dispatch) {
    return {};
}

const mapStateToProps = state => ({
  apiKey: state.security.user  ? state.security.user.apiKey : null ,
  user: state.security.user || null,
  // isAuth: !!state.security.user,
});

export default connect(mapStateToProps, bindAction)(RAM);
