import React, { Component } from 'react';
// import {Loader} from 'react-loaders';

export default class Header extends Component {

  render() {

    // const {imageUrl, alt} = this.props;

    return (
      <header style={styles.header}>
        <button><i className='fa fa-back'/></button>
      </header>
    );
  }
}

const styles = {
  header: {
    width:'100%',
    height:60,
  }
}
