import React, { Component } from 'react';
import { connect } from 'react-redux';

import GridPublications from '../common/publications/GridPublications';
import Api from '../api';
import _ from 'lodash';

import {Header, Footer} from '../common';

class Search extends Component {


  constructor(props){
    super(props);
    this.state = {
      loaded:true,
      message: null,
      messageStyle:'info',
      publications: [],
      kioskPublications: props.publications || (this.props.location.state && this.props.location.state.publications) || []
    }
  }

  setPublications(){
    let publications = _.slice(this.state.publications);

    if (publications.length === 0){
      _.forEach(this.state.kioskPublications,(kioskPub) =>{
        let pubs = kioskPub.kiosk_publications || kioskPub;
         publications = _.unionBy(publications, pubs, 'publication.id');
      });
      this.setState({publications: _.slice(publications)});
    }
  }

  componentDidMount(){

    if (this.state.kioskPublications.length === 0){
      const {  apiKey} = this.props;

      Api.loadPublications(apiKey)
          .then((kioskPublications) => {
            this.setState({loaded:true, kioskPublications,});
            this.setPublications();

          }).catch((error) =>{
            this.setState({message:error.message || error , messageStyle: 'danger', loaded:true,});
          });
    }

    this.setPublications();
  }

  searchPublications = (kioskPubs ) =>{

    let publications = _.slice(this.state.publications);
    let searchTerm = this.props.location.search.split("?q=");
    searchTerm = searchTerm.length ? searchTerm[1]: '';

    publications = _.filter(publications,(kioskPub) =>{
      const publicationName = _.upperCase(kioskPub.publication.name);
      return publicationName.indexOf(_.upperCase(searchTerm)) > -1;
    });

    return publications;
  }

  render() {

    const {loaded, kioskPublications} = this.state;

    return (
      <div>
      <Header {...this.props}/>
        <main className="site-main">
           <div className="columns container">
             <GridPublications
                 loaded={loaded}
                 categeryName={"Resultats"}
                 allowPagination={false}
                 showToolbarSorter={false}
                 publications={this.searchPublications(kioskPublications)} />
          </div>
        </main>
        <Footer {...this.props}/>
      </div>

    );
  }
}

function bindAction(dispatch) {
    return {};
}

const mapStateToProps = state => ({
  apiKey: state.security.user  ? state.security.user.apiKey : null ,
  user: state.security.user || null,
  isAuth: !!state.security.user,
});

export default connect(mapStateToProps, bindAction)(Search);
