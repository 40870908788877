export const en = {
    kiosk: "Kiosk",
    search: "Search",
    search_placeholder: "Search...",
    library: "Library",
    deletting: "Deletting",
    empty_library: "Your library is empty",
    settings: "Settings",
    account: "Account",
    about: "About",
    not_connected: "You are not connected",
    welcome_to: "Welcome to",
    cgu_cgv: "Terms of use / conditions",
    send: "Send",
    rate_us: "Rate us in the {{store}}",
    recommend_to_friend: "Recommend to a friend",
    contact_us:'Contact us !',
    write_us:'Write us !',
    order_by:'Order by',
    loading: '',
    sign_out: "Sign out",
    log_out: 'Log out',
    disconnection: 'Disconnection',
    my_personnals_data: "My personnals data",
    current_kiosk: "You are currently on the digital kiosk of",
    switch_kiosk_instruction: "To change account click on the kiosk name (in black)",
    no_internet_access: 'No internet access',
    continue_without_account: 'Continue without account',
    please_wait: 'Please wait',
    processing: 'processing',

    alert_log_out: {
        title:'Log out',
        message:'Are you sure you want to log out?',
        actions:{
          log_out: 'Log out',
          cancel: 'Cancel',
        }
    },
    alert_remove_pub_library:{
      title: 'Deletting',
      message:{
        one:'Are you sure to want to delete the selected magazine?',
        other:'Are you sure to want to delete the selected magazines?',
      },
       actions:{
        cancel: 'Cancel',
        delete: 'Delete'
      }
    },

    actions:{
        cancel: "Cancel",
        show: "Show",
        read: "Read",
        delete: "Delete",
        sign_in: "Sign in",
        sign_up: "Sign up",
        sign_out: "Sign out",
        register: "Register",
        download: "Download",
        share: "Share",
        rate_app: "Rate app.",
        validate: "Validate",
        add_avatar: 'Add an avatar',
        edit_avatar: 'Edit your avatar',
        add_another_account: 'Add another account',
        refresh: 'Refresh',
        save: 'Save',
    },
    security: {
        login: {
            username: "Username",
            email: "Email",
            password: "Password",
            submit: "Log in"
        }
    },
    profile: {
        profile: "Profile",
        library: "Library",
        add_avatar: 'Add an avatar',
        edit_avatar: 'Edit your avatar',
        add_another_account: 'Add another account',
        my_personnals_data: "My personnals data",
        current_kiosk: "You are currently on the digital kiosk of",
        switch_kiosk_instruction: "To change account click on the kiosk name (in black)",
        change_password: "Change password",
        edit: {
            submit: "Update"
        },
        flash: {
            updated: "The profile has been updated."
        },
        gender:{
          placeholder:'Your are',
          mr:'Lecteur',
          ms:'Lectrice',
        }
    },
    image_picker:{
      title:'Select avatar',
      cancelButtonTitle: 'Cancel',
      takePhotoButtonTitle: 'Take Photo',
      chooseFromLibraryButtonTitle: 'Choose from library',
      permissionDenied: {
        title: 'Permission denied',
        text: 'To be able to take pictures with your camera and choose images from your library.',
        reTryTitle: 'Re-try',
        okTitle: "I'm sure",
      },
      customButtons:{
        removeAvatar: 'Remove avatar',
      },
    },
    change_password: {
        title: "Change password",
        change_password: "Change password",
        submit: "Change password",
        flash: {
            "success": "The password has been changed."
        }
    },
    resetting: {
        check_email: "An email has been sent. It contains a link you must click to reset your password.\nNote: You can only request a new password within %tokenLifetime% hours.\n\nIf you don't get an email check your spam folder or try again.\n",
        request: {
            username: "Username or email address",
            submit: "Reset password"
        },
        reset: {
            submit: "Change password"
        },
        flash: {
            "-success": "The password has been reset successfully."
        },
    },
    layout: {
        logout: "Log out",
        login: "Log in",
        register: "Register",
        write_us: "Write us !",
        forgot_password: "Forgot password ?",
        forgot_code: "Forgot Code ?",
        recommend_app: "Recommend {{app}} to your friends",
        logged_in_as: "Logged in as %username%",
        my_library: "My library",
        "authentication": "Log in/Register",
        "legal_notices": "Legal notices",
    },
    registration:{
      "fill_fields": "Please enter your email address to create an account!",
      'create_an_account': "Create an Account",
      'submit': "Create an Account",
    },

    login:{
      'fill_fields': "If you have an account please enter the username & password her",
      'already_registered': "Already Registered?",
    },
    form: {
        firstname: "Firstname",
        lastname: "Lastname",
        code_licence: "Code/Licence",
        username: "Username",
        email: "Email",
        current_password: "Current password",
        password: "Password",
        password_confirmation: "Repeat password",
        new_password: "New password",
        new_password_confirmation: "Repeat new password",
        subject: "Subject",
        body: "Message",
        phone: "Phone number",
        accept_cgu: "By registering I accept the Terms of Use.",

        errors: {
          email_can_not_be_blank: "Votre email ne doit pas être vide",
          can_not_be_null: "This value should not be null.",
          code_can_not_be_blank: "Votre code ne doit pas être vide",
          firstname_can_not_be_blank: "Votre prénom ne doit pas être vide",
          lastname_can_not_be_blank: "Votre lastname ne doit pas être vide",
          password_can_not_be_blank: "Veuillez renseigner votre mot de passe",
          password_confirmation_can_not_be_blank: "Veuillez confirmer votre mot de passe",
          password_mismatch: " Les deux mots de passe ne sont pas identiques",
          kiosk_can_not_be_blank: "Veuillez sélectionner un kiosque",
          cgu_not_accepted: "You must accept the terms and conditions to register",
        }
    },
    errors:{
      upload_avatar: "A problem occurred when uploading your avatar thank you for retrying later",
    },
    mentions:{
      "mentions": "Legal notices",
      "mentions_detail1": "This website is the exclusive property of",
      "mentions_detail2": "whose head office is located at:",
      "mentions_detail3": "The publication director is",

      "hosting": "Web hosting",
      "hosting_detail1": "RCS Roubaix – Tourcoing 424 761 419 00011",
      "hosting_detail2": "Code APE 6202A",
      "hosting_detail3": "VAT registration Number: FR 22 424 761 419",
      "hosting_detail4": "Head office: : 2 rue Kellermann, 59100 Roubaix (France).",


      "cookies_detail1": "We inform users that when they access our website, we may automatically install and temporarily store a Cookie in the computer’s memory or hard drive.",
      "cookies_detail2": "A cookie is recorded information that does not identify the user, but enables us to memorize information about the user’s visit on the website.",
      "cookies_detail3": "Users acknowledge that they have been informed of this practice. Users can disable cookies by adjusting their browser settings (Google Chrome, Firefox, Internet Explorer, Safari ...). ",
      "cookies_detail4": "iPhone ® and iPad ® are trademarks of Apple Inc",
      "cookies_detail5": "Android® is a trademark of Google Inc ",


      "privateData": "Privacy policy",
      "privateData_detail1": "In compliance with the provisions of the Act of 6 January 1978 relating to Information technology files and liberty, you have the right to access and amend your personal information by writing to the head office.",
      "privateData_detail2": "The Propress Conseil service is registered with the C.N.I.L. (Commission Nationale Informatique et Libertés).",
      "privateData_detail3": "Users of the digital kiosk service are informed that PROPRESS CONSEIL may be required to disclose their personal data, under the conditions provided for by the law, to a judicial or administrative authority duly authorized.",


      "cgvRights": "Intellectual Property Rights",
      "cgvRights_detail1": "The reproduction or representation, as a whole or in part, of the pages, data and any other component part of the site, by any means whatsoever, is forbidden and unless publisher’s formal consent is given previously, constitutes a violation, penalized by clauses L335-2 and following of the Intellectual Property Code.",
      "cgvRights_detail2": "Any use of the site and content for any illegal purposes will be subject to legal action against the offenders.",

      "readBook": "Read",
      "offers": "Latest news",

      "newsletter-title": "Signup for our newsletters",
      "newsletter-error": "Please enter a valid email",
      "newsletter-success": "Signup succeeded, thank you very much for your support",
      "Notre selection presse française": "Our French Press selection",
      "Notre selection presse anglaise": "Our English Press selection"
    }

};
