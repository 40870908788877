import React, { Component } from 'react';
import Publication from './Publication';
import {Loader} from 'react-loaders';
import Translator from '../../translations';

export default class  Publications extends Component {
  render() {

    const {publications, loaded , ...publicationProps} = this.props;


    if (!loaded){
      return (
        <div className="product-items row text-center">
          <h1 className="text-center">{Translator.t('loading')}</h1>
          <div className="margin-top-40 margin-bottom-40">

            <Loader type="ball-pulse-rise" size='lg' styles={{width:45, height:45,}}/>
          </div>
        </div>
      )
    }
    return (

      <div className="row">
        {
          publications.map(function(item, index){
              let publication = item.publication || item;
              return <Publication key={index}  {...publicationProps} publication={publication}/>
          })
        }
      </div>
    );
  }
}

Publications.defaultProps = {
  publications: [],
  loaded: false,
}
