import React, { Component } from "react";

import { connect } from "react-redux";

import GridPublications from "../common/publications/GridPublications";
import Api from "../api";
import _ from "lodash";
import { Loader } from "react-loaders";
import { Header, Footer } from "../common";
import Slider, { SliderItem } from "../common/Slider";
import LoginModal from "./security/LoginModal";
import Helpers from "../helpers";
import Translator from "../translations";

const SHOW_TOOLBAR_SORTER_OPTIONS = [
  { value: "position", label: "Position", orderType: "asc" },
  { value: "name", label: "Titre", orderType: "asc" },
  // {value: 'view_count', label:'Lecture', orderType:'asc'},
];

class Publications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: this.props.kioskPublications.length > 0,
      loginModalVisible: !this.props.isAuth,
      isIpAvailable: true,
      openModalIp: false,
      kioskPublications: this.props.kioskPublications || [],
      kioskMediasSlider: [],
      kioskAsideMedias: [],
      kioskfooterMedias: [],
      message: null,
      messageStyle: "info",
    };
  }
  componentDidMount() {
    const { apiKey } = this.props;

    Api.getKiosk(apiKey)
      .then((kiosk) => {
        const kioskMediasSlider = kiosk.media_links || [];
        const kioskAsideMedias = kiosk.aside_media_links || [];
        const kioskfooterMedias = kiosk.footer_media_links || [];
        let isIpAvailable = false;

        Api.getRequestUserInfo()
          .then((resp) => {
            isIpAvailable = _.indexOf(kiosk.ips, resp.ip) !== -1;
            this.setState({ isIpAvailable });
          })
          .catch((err) => {});

        this.setState({
          kioskMediasSlider,
          kioskAsideMedias,
          kioskfooterMedias,
          isIpAvailable,
        });
      })
      .catch((error) => {
        this.setState({
          message: error.message,
          messageStyle: "danger",
          loaded: false,
        });
      });
  }

  componentWillReceiveProps(nextProps) {
    let kioskPublications = nextProps.kioskPublications;
    this.setState({ kioskPublications, loaded: kioskPublications.length > 0 });
  }

  renderLoader() {
    if (this.props.kioskPublications.length == 0) {
      return (
        <div className="product-items row text-center">
          {<h1 className="text-center">{Translator.t("loading")}</h1>}
          <div className="margin-top-40 margin-bottom-40">
            <Loader
              type="ball-pulse-rise"
              size="lg"
              styles={{ width: 45, height: 45 }}
            />
          </div>
        </div>
      );
    }

    return null;
  }

  renderMessage() {
    const { message, messageStyle } = this.state;

    if (!message) {
      return null;
    }
    return (
      <div className="container-fluid margin-top-100 margin-bottom-100">
        <div className="margin-top-10 margin-bottom-40">
          <div
            className={`flat text-center alert alert-${
              messageStyle || "warning"
            }`}
          >
            {message}
          </div>
        </div>
      </div>
    );
  }
  orderBy(item, category) {
    let myCat = _.find(this.state.kioskPublications, { id: category.id });

    switch (item.value) {
      case "position":
        myCat.kiosk_publications = _.orderBy(
          myCat.kiosk_publications,
          ["id"],
          [item.orderType]
        );
        break;
      case "name":
        myCat.kiosk_publications = _.orderBy(
          myCat.kiosk_publications,
          ["publication.name"],
          [item.orderType]
        );
        break;
      case "view_count":
        myCat.kiosk_publications = _.orderBy(
          myCat.kiosk_publications,
          ["publication.view_count"],
          [item.orderType]
        );
        break;
      default:
    }
    const kioskPublications = _.unionBy(
      this.state.kioskPublications,
      [myCat],
      "id"
    );
    this.setState({ kioskPublications: kioskPublications });
  }
  searchPublications = (kioskPubs) => {
    let publications = [];

    let searchTerm = this.props.history.location.search.split("?q=");
    searchTerm = searchTerm.length ? searchTerm[1] : "";

    publications = _.filter(kioskPubs, (kioskPub) => {
      const publicationName = _.upperCase(kioskPub.publication.name);
      return publicationName.indexOf(_.upperCase(searchTerm)) > -1;
    });

    return publications;
  };

  onSearchBarTextChanged = (searchTerm) => {
    if (searchTerm) {
      this.props.history &&
        this.props.history.push({
          pathname: "/search",
          search: "?q=" + searchTerm,
          state: {
            publications: this.state.kioskPublications,
            searchTerm: searchTerm,
          },
        });
    }
  };
  onView = (event, publication) => {};
  canNotRead = (publication) => {
    this.setState({ loginModalVisible: true, savedPublication: publication });
  };

  onLogged = (user) => {
    if (this.state.savedPublication) {
      let rPublication =
        this.state.savedPublication.publication || this.state.savedPublication;

      const params = {
        apiUrl: process.env.REACT_APP_API_URL,
        kiosk: process.env.REACT_APP_KIOSK_ID,
      };

      const publicationReaderUrl = Helpers.getPublicationReaderUrl(
        rPublication,
        user.apiKey,
        process.env.REACT_APP_READER_URL,
        params
      );
      window.location.href = publicationReaderUrl;
    } else {
      this.setState({ loginModalVisible: false });
    }
  };

  render() {
    const {
      kioskPublications,
      kioskMediasSlider,
      kioskAsideMedias,
      kioskfooterMedias,
    } = this.state;
    const { isAuth, canRead } = this.props;
    const loaded = this.props.kioskPublications.length > 0;
    return (
      <div>
        <Header
          {...this.props}
          onSearchBarTextChanged={this.onSearchBarTextChanged}
        />
        <main className="site-main">
          <section
            id="mainSlider"
            className="container main-slider-area margin-top-30"
          >
            <div className="row">
              {kioskMediasSlider.length > 0 && (
                <div
                  className={`col-md-${kioskAsideMedias.length > 0 ? 9 : 12}`}
                >
                  <Slider className="">
                    {kioskMediasSlider.map((item, i) => (
                      <SliderItem
                        imgProps={{ alt: item.media.name }}
                        className="text-center"
                        url={item.target_url}
                        key={i}
                        imageSrouce={item.media.web_path}
                      />
                    ))}
                  </Slider>
                </div>
              )}
              {kioskAsideMedias.length > 0 && (
                <div className="col-md-3 hidden-xs">
                  {kioskAsideMedias.map((item, i) => {
                    let colSize = Math.ceil(12 / kioskAsideMedias.length);

                    return (
                      <div key={i} className="margin-bottom-20">
                        <a href={item.target_url} target="_blank">
                          <img
                            src={item.media.web_path}
                            alt={item.media.name}
                            className="img img-responsive center-block"
                          />
                        </a>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </section>

          <div className="columns container">
            {this.renderLoader()}
            {this.renderMessage()}

            {kioskPublications.map((kioskPubCategory, index) => {
              return (
                <GridPublications
                  loaded={loaded}
                  authMode={isAuth}
                  canRead={canRead}
                  onView={this.onView}
                  canNotRead={this.canNotRead}
                  key={kioskPubCategory.id}
                  categeryName={kioskPubCategory.name}
                  categeryBgColor={kioskPubCategory.color}
                  allowPagination={false}
                  showToolbarSorter={false}
                  showToolbarSorterOptions={SHOW_TOOLBAR_SORTER_OPTIONS}
                  showToolbarLimiter={false}
                  showModes={false}
                  onOrderChange={(orderType) =>
                    this.orderBy(orderType, kioskPubCategory)
                  }
                  publications={this.searchPublications(
                    kioskPubCategory.kiosk_publications
                  )}
                />
              );
            })}
          </div>
        </main>
        {kioskfooterMedias.length > 0 && (
          <section className="container">
            <div className="row margin-top-40  margin-bottom-20">
              {kioskfooterMedias.map((item, i) => {
                let colSize = Math.ceil(12 / kioskfooterMedias.length);

                return (
                  <div key={i} className={"col-md-" + colSize}>
                    <a href={item.target_url} target="_blank">
                      <img
                        src={item.media.web_path}
                        alt={item.media.name}
                        className="center-block margin-bottom-10 img img-responsive"
                      />
                    </a>
                  </div>
                );
              })}
            </div>
          </section>
        )}

        <Footer {...this.props} />

        <LoginModal
          keyboard
          tabIndex="-1"
          onHide={() => {
            this.setState({ loginModalVisible: false });
          }}
          visible={this.state.loginModalVisible}
          onLogged={this.onLogged}
        />
      </div>
    );
  }
}

function bindAction(dispatch) {
  return {};
}

const mapStateToProps = (state) => ({
  apiKey: state.security.user
    ? state.security.user.apiKey
    : process.env.REACT_APP_API_SECRETE,
  user: state.security.user || null,
  isAuth: !!state.security.user,
  canRead:
    !!state.security.user ||
    (state.appConfig.kiosk.login_mode == "ips" &&
      state.appConfig.kiosk.isAuthByIp),
  kioskPublications: state.appConfig.kioskPublications,
});

export default connect(mapStateToProps, bindAction)(Publications);
