import React, { Component } from "react";
import LoginForm from "./LoginForm";
import ForgotPasswordOrCode from "./ForgotPasswordOrCode";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "react-awesome-modal";
import { loginByIp, login } from "../../actions/security";
import Translator from "../../translations";

class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginError: null,
      registerErrors: [],
      registerUser: null,
      loginUser: null,
      fetching: false,
      showModal: props.visible,
    };
  }

  redirectToReferOr(pathname = "/publications") {
    const locationState = this.props.location.state;
    if (locationState) {
      const referer = locationState.from;
      this.props.history.push(referer);
    } else {
      this.props.history.push(pathname);
    }
  }

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let modelName = e.target.dataset.model || null;

    let model = null;
    if (modelName) {
      model = { ...this.state[modelName] };
    }
    model[name] = value;

    this.setState({ [modelName]: model });
  };
  onLogged = (user, loginByIp) => {
    this.props.onLogged && this.props.onLogged(user, loginByIp);
    this.props.login(user);
    if (this.props.redirectPathname) {
      this.props.redirectToReferOr(this.props.redirectPathname);
    } else if (this.props.redirectToUrl) {
      window.location.href = this.props.redirectToUrl;
    }
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    // Sommes-nous en train d’ajouter de nouveaux éléments à la liste ?
    // Sauvegardons la position de défilement pour la recaler plus tard.
    if (prevProps.visible !== this.props.visible) {
      return this.props.visible;
    }
    return null;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // Si nous avons une valeur sauvegardée, c’est que nous venons d’ajouter des
    // éléments. Ajustons le défilement pour que ces nouveaux éléments ne
    // décalent pas les anciens hors du champ de vision. (ici `snapshot` est la
    // valeur renvoyée par getSnapshotBeforeUpdate.)
    if (snapshot !== null) {
      this.setState({ showModal: snapshot });
    }
  }
  render() {
    const { kioskId, onHide, visible, ...props } = this.props;
    return (
      <Modal
        visible={this.state.showModal}
        className={" flat"}
        onHide={onHide && onHide}
        onClickAway={onHide && onHide}
      >
        <div className="modal-dialog">
          <div className="modal-header">
            <h3 className="title-base text-uppercase text-center">
              {Translator.t("login.already_registered")}
            </h3>
          </div>

          <div className="  modal-body">
            <LoginForm
              kioskId={kioskId}
              loginByIp={false}
              onLogged={this.onLogged}
            />

            <div className="row  text-center margin-top-20">
              <div className="col-md-offset-2 col-md-8 col-md-offset-2">
                <ForgotPasswordOrCode
                  kioskId={kioskId}
                  btnText={Translator.t("layout.forgot_password")}
                />
                <br />
                <br />
                <Link to="/register">
                  {Translator.t("registration.create_an_account")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

function bindAction(dispatch) {
  return {
    login: (user) => dispatch(login(user)),
    loginByIp: (apiKey) => dispatch(loginByIp(apiKey)),
  };
}

const mapStateToProps = (state) => ({
  user: state.security.user,
  kioskId: process.env.REACT_APP_KIOSK_ID,
});

export default connect(mapStateToProps, bindAction)(LoginModal);
