import React, { Component } from 'react';

import { Header, Footer } from './common';
import {
  Home,
  Publications,
  Favorites,
  Contact,
  Login,
  ResettingPassword,
  Profile,
  ChangePassword,
  ChangeCode, NotFound, Cgv
} from './components';
import Cookies from 'universal-cookie';
import _ from 'lodash';
import { connect } from 'react-redux';
import Modal from 'react-awesome-modal';


import Translator from './translations';
import Reader from './reader/Reader';
import { setConfig, setKiosk } from './actions/appConfig';

import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import "./kiosk.css";
import Api from './api';

import { cacheKioskPublications } from './actions/appConfig';

const PrivateRoute = ({ component: Component, condition, redirectPath, ...rest }) => (
  <Route {...rest} render={props => (
    condition ? (
      <Component {...props} />
    ) : (
        <Redirect to={{
          pathname: redirectPath || '/login',
          state: { from: props.location }
        }} />
      )
  )} />
)

const WrapperRouter = ({ component: Component, condition, redirectPath, ...rest }) => (
  <Route {...rest} render={props => (
    condition ? (
      <div>
        <Header {...props} />
        <main className="site-main">
          <Component {...props} />
        </main>
        <Footer {...props} />
      </div>
    ) : (
        <Redirect to={{
          pathname: redirectPath,
          state: { from: props.location }
        }} />
      )
  )} />
)
WrapperRouter.defaultProps = {
  condition: true,
  redirectPath: '/login',
}
const cookies = new Cookies();


class Kiosk extends Component {

  state = {
    showModal: false,
  }
  componentDidMount() {
    Translator.locale = this.props.locale;
    document.title = process.env.REACT_APP_KIOSK_NAME;
    const apiKey = this.props.apiKey;
    const authKey = apiKey || process.env.REACT_APP_API_SECRETE;
    Api.loadPublications(authKey, true, false)
      .then((kioskPublications) => {
        kioskPublications = _.orderBy(kioskPublications, ['position'], ['asc']);
        this.props.cacheKioskPublications(kioskPublications);
      }).catch((error) => {
        this.props.cacheKioskPublications([]);
      });

    Api.getKiosk(authKey)
      .then((kiosk) => {
        this.props.setKiosk({
          ...this.props.kiosk,
          ...kiosk,
        });
      })
      .catch((e) => { });

    Api.getRequestUserInfo()
      .then((requestData) => {
        this.props.setRequestData(requestData);
      })
      .catch(() => { });



    let expires = new Date();
    expires.setDate(expires.getDate() + 1); // 7H

    if (!cookies.get('apiKey')) {

      if (this.props.kiosk.login_mode === 'ips') {
        const loginParams = {
          kiosk: process.env.REACT_APP_KIOSK_ID,
        }

        this.setState({ autoAuth: true });
        Api
          .login(JSON.stringify(loginParams), true)
          .then((response) => {

            this.props.setKiosk({
              ...this.props.kiosk,
              isAuthByIp: true,
            });
            cookies.set('apiKey', response.api_key, { expires, });
          })
          .catch((error) => {

            cookies.remove('apiKey'); 
            
            const messages = [];
            if (this.props.kiosk.ip_required) {
              messages.push("Pour vous connecter à notre kiosque assurez-vous d'être connecter à une borne Wifi.")
              messages.push("Si le problème persiste demander des renseignements à un ageant sur le salon.")
              messages.push("L'agent pourra remonter le problème à notre service technique.")
              messages.push("")
            } else {
              messages.push("Connectez vous pour lire la presse dans votre bibliothèque")
              messages.push("Les magazines sont accessible uniquement par ip")
              messages.push("")
              messages.push("Merci ")
            }
            this.props.setKiosk({
              ...this.props.kiosk,
              isAuthByIp: false,
            })
            this.setState({
              message: error.message || '',
              messages: messages,
              showModal: ((this.props.kiosk.isAuthByIp === false )? false : true),
            });

          });
      }

    }
  }

  renderModal() {
    const { message, messages, showModal } = this.state;
    
    return (
      <Modal
        visible={showModal}
        effect="fadeInUp"
        onClickAway={() => !this.props.kiosk.ip_required ? this.setState({ showModal: false }) : null}
        styles={{ borderRadius: 0 }} >
        <div className='modal-dialog'>
          <div className='modal-header'>
            <h3>Error</h3>
          </div>

          <div className="text-center modal-body">

            {!!message && <h4> {message}</h4>}
            <p></p>
            {
              !!messages && _.map(messages, (msg, i) => <p key={i}>{msg}</p>)
            }

          </div>
        </div>
      </Modal>
    )
  }
  render() {

    const { isAuth, } = this.props;

    return (
      <Router>
        <Switch>
          <WrapperRouter exact path="/" component={Home} />
          <PrivateRoute path="/publications" condition={true} component={Publications} redirectPath='/login' />
          <WrapperRouter path="/profile" condition={isAuth} component={Profile} />
          <WrapperRouter path="/contact" component={Contact} />
          <WrapperRouter path="/login" component={Login} />
          <WrapperRouter path="/resetting_password" component={ResettingPassword} />
          <WrapperRouter path="/change_password" condition={isAuth} component={ChangePassword} />
          <WrapperRouter path="/change_code" condition={isAuth} component={ChangeCode} />
          <WrapperRouter path="/register" component={Login} />
          <WrapperRouter path="/cgv" component={Cgv} />
          <Route path="/reader" component={Reader} />
          <WrapperRouter path="/favorites" condition={isAuth} component={Favorites} redirectPath='/login' />
          <WrapperRouter component={NotFound} />
        </Switch>

        {this.renderModal()}
      </Router>
    );
  }
}

function bindAction(dispatch) {
  return {
    setConfig: config => dispatch(setConfig(config)),
    setKiosk: config => dispatch(setKiosk(config)),
    cacheKioskPublications: kioskPublications => (dispatch(cacheKioskPublications(kioskPublications))),
  };
}

const mapStateToProps = state => ({
  apiKey: state.security.user ? state.security.user.apiKey : null,
  user: state.security.user || null,
  isAuth: !!state.security.user,
  locale: state.appConfig.locale,
  kiosk: state.appConfig.kiosk,
});

export default connect(mapStateToProps, bindAction)(Kiosk);
