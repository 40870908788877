import React, { Component } from 'react';
import { Loader } from 'react-loaders';

import Api from '../../api';
import Translator from '../../translations';

export default class ForgotPasswordOrCode extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formVisible: false,
      fetching: false,
      email: null,
      message: null,
      messageType: 'info',
    }
  }

  submit = () => {
    // this.setState({loginError:null,fetching:true,});
    const { kioskId, recoverCode, recoverPassword } = this.props;
    const { email } = this.state;
    if (!email) {
      this.setState({ message: Translator.t('form.errors.can_not_be_null'), messageType: 'danger' })
    } else {
      this.setState({ fetching: true })

      let requestData = {
        email,
        kiosk: kioskId,
      }
      let mode = 'password';
      if (recoverCode) {
        mode = 'code';
      }
      else if (recoverPassword) {
        mode = 'password';
      }
      Api.recoverPasswordOrCode(JSON.stringify(requestData), mode)
        .then((response) => {

          this.setState({ message: response.message, messageType: response.type || 'success', fetching: false, });
          this.props.onRecover && this.props.onRecover(response);
        })
        .catch((error) => {
          this.setState({ message: error.message || error, messageType: 'danger', fetching: false, });
        })
    }

  }


  render() {
    const { message, formVisible, messageType, fetching } = this.state;
    const { btnText, btnSubmitText } = this.props;
    if (!formVisible) {
      return <a onClick={() => this.setState({ formVisible: true })}>{btnText}</a>
    }
    return (
      <form className="text-center">
        {
          message &&
          <div className={`flat text-center alert alert-${messageType || 'warning'}`}>
            {message}
          </div>
        }
        <div className="form-group">
          <input disabled={fetching} required type="text" name="email" onChange={(e) => this.setState({ email: e.target.value })} className="form-control" placeholder={Translator.t('form.email')} />
        </div>
        {fetching && <Loader type="ball-beat" active={fetching} />}
        {!fetching && <button disabled={fetching} type="submit" onClick={this.submit} className="btn btn-inline">{btnSubmitText}</button>}

      </form>
    );
  }
}

ForgotPasswordOrCode.defaultProps = {
  kioskId: null,
  btnText: 'Click Here',
  btnSubmitText: 'Submit',
}
