import {API_URL,FREE_SECRET_API_KEY,checkStatus} from './common';
import _ from 'lodash';
import Translator  from '../translations';

const __DEV__ = process.env.NODE_ENV === 'development';
async function loadPublications(apiKey, categorize = true, view_count=false) {
    // let finalApiKey = apiKey || '';
    let finalApiKey = apiKey ;

    __DEV__ && console.log("load publications", API_URL+'/kiosk/publications' );
    __DEV__ && console.log("load publications with secret", finalApiKey );

    // let url = API_URL+'/kiosk/publications?api_key=' + finalApiKey ;
    let url = API_URL+'/kiosk/publications' ;
    if (categorize) url +="?categorize=true";
    if (categorize && view_count) {
      url +="&view_count=true"
    }else if (!categorize && view_count) {
        url +="?view_count=true";
    }

    return fetch(url, {
              accept: 'application/json',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                locale: Translator.locale,
                api_key:finalApiKey,
                Authorization:finalApiKey,
              },
            })
            .then(checkStatus)
            // .then((resp) => this.parsePublications(resp, categorize))
}

async function getUserFavorites(apiKey, categorize = true) {
    // let finalApiKey = apiKey || '';
    let finalApiKey = apiKey ;
    if (!finalApiKey) finalApiKey =  FREE_SECRET_API_KEY;

    __DEV__ && console.log("load publications", API_URL+'/user/favorites');

    let url = API_URL+'/user/favorites' ;
    if (categorize) url +="?categorize=true";

    return fetch(url, {
              accept: 'application/json',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                locale: Translator.locale,
                Authorization:finalApiKey,
              },
            })
            .then(checkStatus)
            // .then(parsePublications)
}

async function library(apiKey) {
  let finalApiKey = apiKey || -1;

    return fetch(API_URL+'/library', {
            accept: 'application/json',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              locale: Translator.locale,
              Authorization:finalApiKey,
            },
    })
    .then(checkStatus)
    .then(parsePublications)
}

async function addToFavorites(apiKey, publicationNumberId) {
  const parameters = {
      publication_number:  publicationNumberId
  };
  return fetch(Api.API_URL+'/user/add/favorite',{
         method: 'POST',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           locale: Translator.locale,
           Authorization:apiKey,
         },
         body: JSON.stringify(parameters)
        }).then(checkStatus);
}

function removeFromFavorites(apiKey, publicationNumberId) {

    const parameters = {
        publication_number: publicationNumberId
    };

    return fetch(Api.API_URL+'/user/remove/favorite',{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        locale: Translator.locale,
        Authorization:apiKey,
      },
      body: JSON.stringify(parameters)
    }).then(checkStatus);

}

function tagOjd(tagOjd, date) {
    return fetch("https://acpm-suivivn.com/track?link="+ tagOjd +'&n=' + date)
}

function logStatistics(apiKey, parameters) {
  let finalApiKey = apiKey || FREE_SECRET_API_KEY;

  return fetch(Api.API_URL+'/statistics/add_log',{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              locale: Translator.locale,
              Authorization:finalApiKey,
            },
            body: parameters
        })
        .then(checkStatus)
}

/**
 * Accept kioskPublication has publication object or publication
 * @param item
 */
function parsePublication(item) {

    let publication = item.publication || item;
    const directory = publication.publication_number.directory;
    const imgPath = directory+"1-large.jpg";
    const thumbImgPath = directory+"1.jpg";

    const pdfRemoteSourcePath = _.replace(directory,'-images/', '.pdf');

    return {
        id: publication.publication_number.id,
        publicationId: publication.id,
        publicationOriginalId: publication.original_id,
        publicationNumberId: publication.publication_number.id,
        publicationNumberOriginalId: publication.publication_number.original_id,
        countPage: publication.publication_number.page_number,
        title: publication.name,
        logo:publication.cover || null,
        coverPath:imgPath,
        coverSource:{
          uri:thumbImgPath,
        },
        coverThumbPath:thumbImgPath,
        coverThumbSource:{
          uri:thumbImgPath,
        },
        remotePdfPath: pdfRemoteSourcePath,
        pdfPath: pdfRemoteSourcePath,
        directory: directory,
        inLibrary: publication.publication_number.in_library,
        inFavorites: publication.publication_number.favorites,
        ojd: publication.publication_number.ojd || {}
    }
}

function parsePublications(items, categorize=false) {
    let publications = [];

    _.forEach(items, (value) => {
        let parsing =  value.kioskPublications ||  value.kiosk_publications || value;
        let category = {};
        let pubs =[];

        if (_.isArray(parsing)){
          _.forEach(parsing, (value2) => {
            pubs.push(parsePublication(value2));
          })
        }else{
          pubs.push(parsePublication(parsing));
        }
        if (categorize){
          category.id = value.id || -1;
          category.name = value.name || 'NC';
          category.position = value.position || 1;
          category.publications =  pubs;
          publications.push(category);
        }else{
          publications.push(pubs)
        }
    });

    return publications;
}

const Api = { API_URL,
    loadPublications, library, tagOjd,
    logStatistics, getUserFavorites,addToFavorites, removeFromFavorites,
    parsePublication, parsePublications
};

export default Api;
