import React, { Component } from 'react';

import Translator from '../translations';

export default class Cgv extends Component {

  render() {
    return (
      <div className="container margin-top-40 margin-bottom-40">
        <div id="mentions">
            <div>
                <h2 className="text-center">{ Translator.t('mentions.mentions')}</h2>
                <p> { Translator.t("mentions.mentions_detail1")  }
                    <span>&nbsp;PROMOTION PRESSE CONSEIL</span>
                    (RCS Paris B 429 833 338)
                    <br />

                     { Translator.t("mentions.mentions_detail2")  } <br/>

                    &nbsp;15 rue Claude Tillier, 75012 PARIS <br/>

                     { Translator.t("mentions.mentions_detail3")  }

                    <span>&nbsp;Jérôme Chikar</span><br/>

                    E-mail : <a id="email"><b style={{fontSize: 16, display: 'inline'}}>jc[@]propress.fr</b></a>
                </p>
                <div id="hosting">
                    <span> { Translator.t("mentions.hosting")  } </span>
                    <p>
                         { Translator.t("mentions.hosting_detail1")  } <br/>
                         { Translator.t("mentions.hosting_detail2")  } <br/>
                         { Translator.t("mentions.hosting_detail3")  } <br/>
                         { Translator.t("mentions.hosting_detail4")  }
                    </p>
                </div>
                <div id="cookies">
                    <span>Cookies</span>
                    <p>
                         { Translator.t("mentions.cookies_detail1")  }
                        <br/>
                         { Translator.t("mentions.cookies_detail2")  }
                        <br/>
                         { Translator.t("mentions.cookies_detail3")  }
                        <br/>
                         { Translator.t("mentions.cookies_detail4")  }
                        <br/>
                         { Translator.t("mentions.cookies_detail5")  }
                    </p>
                </div>
                <div id="data">
                    <span> { Translator.t("mentions.privateData")  }</span>
                    <p>
                         { Translator.t("mentions.privateData_detail1")  }
                        <br/>
                         { Translator.t("mentions.privateData_detail2")  }
                        <br/>
                         { Translator.t("mentions.privateData_detail3")  }
                    </p>
                </div>
                <div id="rights">
                    <span> { Translator.t("mentions.cgvRights")  }</span>
                    <p>
                         { Translator.t("mentions.cgvRights_detail1")  }
                        <br/>
                         { Translator.t("mentions.cgvRights_detail2")  }.
                    </p>
                </div>
            </div>
        </div>
      </div>
    );
  }
}
