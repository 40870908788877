
import Translator from '../translations';

import {
  SWITCH_LOCALE,SET_CONFIG,CACHE_KIOSK_PUBLICATIONS,
  SET_KIOSK
} from '../actions/appConfig';
 

const initialState = {
  locale: 'fr',
  kiosk:null,
  kioskPublications:[], 
};

export default function (state = initialState, action) {

    if (action.type === SWITCH_LOCALE){
      Translator.locale = action.locale;
      return {
        ...state,
        locale:action.locale,
      }
    }
    else if (action.type === SET_CONFIG){
      const config = action.config;

      return {
        ...state,
        ...config,
      }
    }
    else if (action.type === CACHE_KIOSK_PUBLICATIONS){
      const kioskPublications = action.kioskPublications;

      return {
        ...state,
        kioskPublications,
      }
    }
    else if (action.type === SET_KIOSK){
 
      return {
        ...state,
        kiosk:action.kiosk,
      }
    }

  return state;
}
