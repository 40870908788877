import React, { Component } from 'react';
import { Loader } from 'react-loaders';

import Translator from '../../translations';

import Api from '../../api';

const DEFAULT_AVATAR_PLACEHOLDER = 'images/placeholder.png';

export default class AvatarForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      avatarSrc: props.avatar || null,
      avatar: null,
      file: null,
      message: null,
      messageType: 'info',
      fetching: false,
    }
  }

  uploadAvatar = () => {
    this.setState({ message: null, fetching: true, });

    if (!this.state.avatar) {

      this.setState({ message: Translator.t('form.errors.can_not_be_null'), messageType: 'danger', fetching: false, });

    }
    else {
      this.setState({ message: null, fetching: true, });

      Api.uploadUserPicture(this.props.apiKey, this.state.file)
        .then((response) => {

          // const user = Object.assign({},{
          //   ...response,
          //   // kiosk:this.props.kiosk,
          //   apiKey:response.api_key,
          // });
          this.props.onUploadAvatar && this.props.onUploadAvatar(response);

          this.setState({ message: 'Avatar uploaded', messageType: 'success', fetching: false, });
        })
        .catch((error) => {
          this.setState({ message: error.message || error, messageType: 'danger', fetching: false, });
        })
    }
  }

  handleInput = (e) => {
    const value = e.target.value;
    const file = e.target.files[0];
    let reader = new FileReader();
    if (file) {
      reader.onloadend = () => {
        this.setState({
          file: file,
          avatarSrc: reader.result,
          avatar: value,
        });
      }

      reader.readAsDataURL(file)
    } else {
      this.setState({
        file: null,
        avatarSrc: DEFAULT_AVATAR_PLACEHOLDER,
        avatar: null,
      });
    }


    console.warn(file, value);

    // this.setState({[modelName]:model,});
  }

  render() {
    const { message, messageType, fetching, } = this.state;
    const avatarSrc = this.state.avatarSrc || DEFAULT_AVATAR_PLACEHOLDER;

    return (
      <form className="text-center">
        {
          message &&
          <div className={`flat text-center alert ${messageType || 'warning'}`}>
            {message}
          </div>
        }

        <div className="row">
          <div className="col-md-3">
            <img src={avatarSrc} className="img img-reponsive" alt="User avatar" />
          </div>
          <div className="col-md-9">
            <div className="form-group">
              <input disabled={fetching} type="file" accept=".png,.jpg,.jpeg" name="avatar" onChange={this.handleInput} className="form-control" placeholder={Translator.t('form.avatar')} />
            </div>
          </div>
        </div>
        <Loader type="ball-beat" active={fetching} />

        {!fetching && <button disabled={fetching} type="submit" onClick={this.uploadAvatar} className="btn btn-inline">{Translator.t("actions.save")}</button>}

      </form>
    );
  }
}
