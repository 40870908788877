import React, { Component } from 'react';
import LoginForm from './LoginForm';

import { connect } from 'react-redux';
import {loginByIp, login} from '../../actions/security';
// import Translator from '../../translations';

class  Login extends Component {

  constructor(props){
    super(props);
    this.state = {
      loginError: null,
      registerErrors: [],
      registerUser: null,
      loginUser: null,
      fetching: false,
    }
  }
  register = () =>{
    const {registerUser} = this.state;
    console.warn(registerUser);
  }


  handleInput = (e) =>{
    const name = e.target.name;
    const value = e.target.value;
    let modelName = e.target.dataset.model || null;

    let model = null;
    if (modelName){
      model =  {...this.state[modelName]};
    }
      model[name] = value;

    this.setState({[modelName]:model,});
  }
  onLogged = (user,loginByIp) =>{
    this.props.login(user)
    this.props.onLogged && this.props.onLogged(user,loginByIp);
    this.props.history.push("/publications");
  }
  render() {

    return (
      <div className="container-fluid margin-top-40">
          <div className="page-title-base container">
              <h1 className="title-base">authentication</h1>
          </div>
          <div className="container">
              <div className="block-form-login">

                  <div className="block-form-create">
                      <div className="block-title">
                          Create an Account
                      </div>
                      <div className="block-content">
                          <p>Please enter your email address to create an account!</p>
                          <form>
                          <div className="row">
                            <div className="col-md-6 col-xs-12">
                              <div className="form-group">
                                  <input required type="text" data-model="registerUser" name="firstname" onChange={this.handleInput} className="form-control" placeholder="Your first name"/>
                              </div>
                            </div>
                            <div className="col-md-6 col-xs-12">
                              <div className="form-group">
                                  <input required type="text" data-model="registerUser" name="lastname" onChange={this.handleInput} className="form-control" placeholder="Your last name"/>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                              <input required type="text" data-model="registerUser" name="email" onChange={this.handleInput} className="form-control" placeholder="Your email"/>
                           </div>
                          <div className="form-group">
                              <input required type="password" data-model="registerUser" name="password" onChange={this.handleInput} className="form-control" placeholder="Password"/>
                          </div>
                          <div className="form-group">
                              <input required type="password" data-model="registerUser" name="code" onChange={this.handleInput} className="form-control" placeholder="Code"/>
                          </div>
                          <button type="button" onClick={this.register} className="btn btn-inline">Register</button>
                          </form>
                      </div>
                  </div>

                  <div className="block-form-registered">

                      <div className="block-title">
                          Already Registered?
                      </div>
                      <div className="block-content">
                          <p>If you have an account please enter the username & password her</p>
                          <LoginForm kioskId={7} loginByIp={false} onLogged={this.onLogged} />
                      </div>

                  </div>

              </div>

              <div className="block-forgot-pass">
                  Forgot your password ? <a href="">Click Here</a>
              </div>
          </div>

          <div className="block-service">
              <div className="container">
                  <div className="row">
                      <div className="col-sm-4">
                          <div className="item">
                              <span className="icon">
                                  <img src="images/icon/index1/service1.png" alt="service"/>
                              </span>
                              <strong className="title">Member Safe Shopping</strong>
                              <span>Safe Shopping Guarantee</span>
                          </div>
                      </div>
                      <div className="col-sm-4">
                          <div className="item">
                              <span className="icon">
                                  <img src="images/icon/index1/service2.png" alt="service"/>
                              </span>
                              <strong className="title">30- day return products</strong>
                              <span>Moneyback guarantee</span>
                          </div>
                      </div>
                      <div className="col-sm-4">
                          <div className="item">
                              <span className="icon">
                                  <img src="images/icon/index1/service3.png" alt="service"/>
                              </span>
                              <strong className="title">Free Shipping worldwide</strong>
                              <span>On oder over $100</span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    );
  }
}


function bindAction(dispatch) {
    return {
      login:user => dispatch(login(user)),
      loginByIp:apiKey => dispatch(loginByIp(apiKey)),
    };
}

const mapStateToProps = state => ({
  user: state.security.user,
});

export default connect(mapStateToProps, bindAction)(Login);
