import _ from "lodash";
import type { Action } from "../actions/types";
import {
  SECURITY_USER_UPDATE,
  SECURITY_LOGOUT,
  SECURITY_LOGIN_BY_IP,
  SECURITY_LOGIN,
  SECURITY_SWITCH_USER_ACCOUNT,
  SECURITY_MODAL_NO_AUTHENTICATED_STATE,
} from "../actions/security";

export type State = {
  user: Object,
  loginByIpApiKey: any,
  userAccounts: any,
  modalNoAuthenticatedState: string,
};

// import {downloadImage, getUserAvatarFile, getFile} from '../helpers/common';

const initialState = {
  user: null,
  loginByIpApiKey: null,
  userAccounts: [],
  modalNoAuthenticatedState: "opened",
};

export default function (state: State = initialState, action: Action): State {
  if (action.type === SECURITY_USER_UPDATE) {
    let user = _.find(state.userAccounts, { id: action.user.id });
    let userIndex = _.findIndex(state.userAccounts, { id: action.user.id });
    //merge user info
    user = { ...user, ...action.user };

    //remove old user
    //set new user
    // state.userAccounts = _.unionBy(state.userAccounts,[user], 'id');
    state.userAccounts[userIndex] = user;
    user = _.find(state.userAccounts, { id: action.user.id });

    if (!state.user || state.user.id === user.id) {
      state.user = user;
    }

    return {
      ...state,
    };
  } else if (action.type === SECURITY_LOGIN) {
    const user = action.user;
    // if (user.avatar && user.avatar.web_path){
    //
    //   let avatarPath = getUserAvatarFile(user);
    //    downloadImage(user.avatar.web_path, avatarPath, (progress) => {
    //     if (progress === 1 ){
    //        user.avatarSource = {
    //         uri: getFile(avatarPath),
    //       }
    //     }
    //   });
    // }
    user.apiKey = user.apiKey || user.api_key;
    state.user = user;
    state.userAccounts = _.unionBy(state.userAccounts, [user], "id");
    state.modalNoAuthenticatedState = "closed";

    return {
      ...state,
    };
  } else if (action.type === SECURITY_SWITCH_USER_ACCOUNT) {
    const user = action.user;
    state.user = user;

    state.userAccounts = _.unionBy(state.userAccounts, [user], "id");

    return {
      ...state,
    };
  } else if (action.type === SECURITY_LOGIN_BY_IP) {
    state.loginByIpApiKey = true;
    return {
      ...state,
    };
  } else if (action.type === SECURITY_LOGOUT) {
    state.user = null;
    state.userAccounts = _.reject(state.userAccounts, {
      apiKey: action.apiKey,
    });

    if (state.userAccounts.length > 0) {
      state.user = _.last(state.userAccounts);
    } else {
      state.modalNoAuthenticatedState = "opened";
    }
    return {
      ...state,
    };
  } else if (action.type === SECURITY_MODAL_NO_AUTHENTICATED_STATE) {
    state.modalNoAuthenticatedState = action.state;

    return {
      ...state,
    };
  }

  return state;
}
