import React, { Component } from 'react';

export default class NotFound extends Component {


  render() {

    return (
      <div className="columns container margin-top-100 margin-bottom-100">
        <h1 className="text-center">NotFound</h1>
      </div>

    );
  }
}
