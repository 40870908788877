import React, { Component } from "react";
import { connect } from "react-redux";

import Api from "../api";
import _ from "lodash";
import { Loader } from "react-loaders";
import { ResettingPassword } from "../components";
import Translator from "../translations";

import { login } from "../actions/security";

class Home extends Component {
  state = {
    locationParams: null,
    message: null,
    messageType: "info",
  };

  componentDidMount() {
    const { history } = this.props;
    let params = this.getParameters();
    this.setState({ locationParams: params });

    const { apiKey, recover_token, confirmation_token, ppc_qrcode } = params;

    if (apiKey) {
      const user = Object.assign(
        {},
        {
          apiKey: apiKey,
        }
      );
      this.props.login(user);
      history.push("/publications");
    } else if (recover_token) {
      history &&
        history.push({
          pathname: "/resetting_password",
          query: { token: recover_token },
        });
    } else if (confirmation_token) {
      Api.confirmRegistration(confirmation_token)
        .then((response) => {
          this.setState({
            locationParams: _.reject(params, "confirmation_token"),
            message: response.message,
            messageType: response.type,
          });
          if (response.api_key) {
            Api.getUserInfo(response.api_key)
              .then((user) => {
                this.props.login(user);
                setTimeout(() => {
                  history.push("/publications");
                }, 2000);
              })
              .catch(() => {
                setTimeout(() => {
                  history.push("/login");
                }, 2000);
              });
          } else {
            setTimeout(() => {
              history.push("/login");
            }, 2000);
          }
        })
        .catch((error) => {
          this.setState({
            locationParams: _.reject(params, "confirmation_token"),
            message: error.message || error,
            messageType: "danger",
          });
        });
    } else if (ppc_qrcode) {
      history.push({ pathname: "/register", query: { code: ppc_qrcode } });
    } else {
      history.push("/publications");
    }
  }

  getParameters() {
    const { location } = this.props;
    let params = {},
      requestParams = {};
    const { search, hash } = location;
    requestParams = _.merge(_.split(search, "#"), _.split(search, "?"));
    requestParams = _.merge(requestParams, _.split(hash, "?"));
    requestParams = _.merge(requestParams, _.split(hash, "?"));

    _.forEach(requestParams, (item, i) => {
      let splited = _.split(item, "=");
      if (splited.length === 2) {
        params[splited[0]] = splited[1];
      }
    });

    return params;
  }

  getParameter(key, defaultValue = null) {
    let locationParams = this.state.locationParams;

    if (this.state.locationParams === null) {
      locationParams = this.getParameters();
    }
    return locationParams[key] || defaultValue;
  }

  renderRegistrationCompletteLoader() {
    const confirmationToken = this.getParameter("confirmation_token");
    if (!confirmationToken) return null;

    return (
      <div className="margin-top-40 margin-bottom-40">
        <h1>{Translator.t("processing")}</h1>
        <Loader
          type="ball-beat"
          size={"lg"}
          active
          styles={{ width: 40, height: 40 }}
        />
      </div>
    );
  }

  renderResettingPassword() {
    const recover_token = this.getParameter("recover_token");
    if (!recover_token) return null;

    return (
      <div className="margin-top-40 margin-bottom-40">
        <ResettingPassword
          token={recover_token}
          onResetting={() => {
            setTimeout(() => {
              this.props.history && this.props.history.push("/login");
            }, 2000);
          }}
        />
      </div>
    );
  }

  render() {
    const { message, messageType } = this.state;

    return (
      <div className="container text-center margin-top-40 margin-bottom-40">
        <div className="margin-top-40 margin-bottom-40">
          {message && (
            <div
              className={`flat text-center alert alert-${
                messageType || "warning"
              }`}
            >
              {message}
            </div>
          )}
          {this.renderRegistrationCompletteLoader()}
          {this.renderResettingPassword()}
        </div>
      </div>
    );
  }
}

function bindAction(dispatch) {
  return {
    login: (user) => dispatch(login(user)),
  };
}

const mapStateToProps = (state) => ({
  apiKey: state.security.user ? state.security.user.apiKey : null,
  user: state.security.user || null,
});

export default connect(mapStateToProps, bindAction)(Home);
