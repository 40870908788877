import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore,persistCombineReducers,persistReducer } from 'redux-persist';
import createEncryptor from 'redux-persist-transform-encrypt'
import reduxThunk from 'redux-thunk';

import storage from 'redux-persist/es/storage'
import rootReducers  from '../reducers';// where reducers is a object of reducers

const kioskName = process.env.REACT_APP_KIOSK_NAME;

const encryptor = createEncryptor({
  secretKey: kioskName,
  onError: function(error) {
    // Handle the error.
  }
})

const config = {
    key: 'root',
    storage,
    transforms: [encryptor]
    // debug: true //to get useful logging
};

const reduxMulti =({dispatch}) => {
  return next => action =>
    Array.isArray(action)
      ? action.filter(Boolean).map(dispatch)
      : next(action)
}

let mainMiddleware = [reduxThunk,reduxMulti];

const configureStore = (callback) => {

  const reducers = persistCombineReducers(config, rootReducers);
  const enhancers = [applyMiddleware(...mainMiddleware)];
  const persistConfig = {
    enhancers
  };
  const store = createStore(reducers, undefined, compose(...enhancers));
  const persistor = persistStore(store, persistConfig, () => {
       
      callback && callback(store, persistor)
  });

  return store;
}

export default configureStore;
