import React, { Component } from "react";
import _ from "lodash";
import { Loader } from "react-loaders";

import Api from "../../api";
import Translator from "../../translations";

import { isNullOrUndefined } from "util";

export default class RegisterForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      registerUser: {
        code: this.props.code || "",
      },
      fetching: false,
    };
  }

  hasError(field) {
    return this.getError(field) !== undefined;
  }

  getErrorClass(field) {
    return this.hasError(field) ? "has-error" : "";
  }

  getError(field) {
    return _.find(this.state.errors, { property_path: field });
  }

  getValue(field, defaultValue = "") {
    return this.state.registerUser
      ? this.state.registerUser[field]
      : defaultValue;
  }
  register = () => {
    if (this.getValue("optin")) {
      this.setState({
        errors: [],
        fetching: true,
        message: "",
        messageType: "info",
      });
      let expiredAt = new Date();
      expiredAt.setDate(expiredAt.getDate() + 30); // 1 mois

      const { registerUser } = this.state;
      let registerData = {
        ...registerUser,
        kiosk: this.props.kioskId,
        validationGroup: "API",
        enabled: this.props.enabled,
        expiredAt: expiredAt.toISOString(),
      };

      Api.register(JSON.stringify(registerData), this.props.regiserByIp)
        .then((response) => {
          this.setState({
            registerUser: null,
            fetching: false,
            message: response.message,
            messageType: response.type || "success",
          });
          this.props.onRegister &&
            this.props.onRegister(registerUser, response);
        })
        .catch((error) => {
          this.setState({
            errors: error.validationErrors || error.message || error,
            fetching: false,
            messageType: error.type || "danger",
          });
        });
    }
  };

  handleInput = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    let modelName = e.target.dataset.model || null;

    let model = null;
    if (modelName) {
      model = { ...this.state[modelName] };
    }
    model[name] = value;
    let errors = this.state.errors;

    _.remove(errors, { property_path: name });

    this.setState({ [modelName]: model });
  };

  renderFieldError(field) {
    let error = this.getError(field);
    if (error === undefined) return null;
    return <small className="text-danger">{error.message}</small>;
  }

  renderError() {
    const { errors, messageType } = this.state;
    if (!errors || typeof errors !== "string") return null;
    const className = `alert alert-${messageType} flat`;
    return <div className={className}>{errors}</div>;
  }

  render() {
    const { fetching, message, messageType } = this.state;

    return (
      <form style={{ position: "relative" }} className="text-center">
        {message && (
          <div className={`flat text-center alert alert-${messageType || "warning"}`}>
            {message}
          </div>
        )}
        <div>{this.renderError()}</div>
        <div className="row">
          <div className="col-md-6 col-xs-12">
            <div className={"form-group " + this.getErrorClass("firstname")}>
              <input
                disabled={fetching}
                required
                type="text"
                data-model="registerUser"
                name="firstname"
                onChange={this.handleInput}
                className="form-control"
                placeholder={Translator.t("form.firstname")}
              />
              {this.renderFieldError("firstName")}
            </div>
          </div>
          <div className="col-md-6 col-xs-12">
            <div className={"form-group " + this.getErrorClass("lastname")}>
              <input
                disabled={fetching}
                required
                type="text"
                data-model="registerUser"
                name="lastname"
                onChange={this.handleInput}
                className="form-control"
                placeholder={Translator.t("form.lastname")}
              />
              {this.renderFieldError("lastName")}
            </div>
          </div>
        </div>
        <div className={"form-group " + this.getErrorClass("email")}>
          <input
            disabled={fetching}
            required
            type="text"
            data-model="registerUser"
            name="email"
            onChange={this.handleInput}
            className="form-control"
            placeholder={Translator.t("form.email")}
          />
          {this.renderFieldError("email")}
        </div>
        <div
          className={
            "form-group " +
            (this.getErrorClass("password") ||
              this.getErrorClass("plainPassword"))
          }
        >
          <input
            disabled={fetching}
            required
            type="password"
            data-model="registerUser"
            name="password"
            onChange={this.handleInput}
            className="form-control"
            placeholder={Translator.t("form.password")}
          />
          {this.renderFieldError("password")}
          {this.renderFieldError("plainPassword")}
        </div>

        <div className={"form-group " + this.getErrorClass("code")}>
          <input
            disabled={fetching}
            required
            type="text"
            data-model="registerUser"
            name="code"
            onChange={this.handleInput}
            value={this.getValue("code", "")}
            className="form-control"
            placeholder={Translator.t("form.code")}
          />
          {this.renderFieldError("code")}
        </div>

        <div className={"form-group " + this.getErrorClass("optin")}>
          <label>
            <input
              data-model="registerUser"
              type="checkbox"
              name="optin"
              onChange={this.handleInput}
              required="required"
            />{" "}
            {Translator.t("form.accept_cgu")}
          </label>
          <div>
            {!this.getValue("optin") && (
              <small className="text-danger">
                {Translator.t("form.errors.cgu_not_accepted")}
              </small>
            )}
          </div>
        </div>
        <Loader type="ball-beat" active={fetching} />

        {!fetching && (
          <button
            disabled={fetching}
            type="submit"
            onClick={this.register}
            className="btn btn-inline"
          >
            {Translator.t("registration.submit")}
          </button>
        )}
      </form>
    );
  }
}

RegisterForm.defaultProps = {
  enabled: false,
};
