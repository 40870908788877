
import Translator from '../translations';
import Api from '../api';
import _ from 'lodash';

export const SWITCH_LOCALE = 'SWITCH_LOCALE';
export const SET_KIOSK = 'SET_KIOSK';
export const SET_CONFIG = 'SET_CONFIG';
export const CACHE_KIOSK_PUBLICATIONS = 'CACHE_KIOSK_PUBLICATIONS';

export function switchLocale(locale='fr') {

  console.warn("locale", locale);
  
  return (dispatch, getState) =>{
    const  {user} = getState().security ;
    Translator.locale = locale;

      const authKey = !!user ? user.apiKey : process.env.REACT_APP_API_SECRETE;

      Api.loadPublications(authKey, true, false)
        .then((kioskPublications) => {
          kioskPublications = _.orderBy(kioskPublications, ['position'], ['asc']);
          dispatch([
            cacheKioskPublications(kioskPublications),
            setLocale(locale)
          ])
        }).catch((error) => {
          dispatch([
            setLocale(locale)
          ])
        });
    
 }
}

export function setLocale(locale='fr') {
  return {
    type: SWITCH_LOCALE,
    locale: locale,
  };
}

export function setConfig(config) {
  return {
    type: SET_CONFIG,
    config: config,
  };
}

export function setKiosk(kiosk) {
  return {
    type: SET_KIOSK,
    kiosk,
  };
}

export function cacheKioskPublications(kioskPublications) {

  return {
    type: CACHE_KIOSK_PUBLICATIONS,
    kioskPublications: kioskPublications,
  };
}
