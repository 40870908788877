import I18n from 'i18n-js'
import {en} from './messages.en';
import {fr} from './messages.fr';
import {es} from './messages.es';

I18n.defaultLocale = "fr";
I18n.locale = "fr";

I18n.fallbacks = true;

I18n.translations = {
    en: en,
    fr: fr,
    es: es,
};


export default I18n;
