import React, { Component } from "react";
import { connect } from "react-redux";

import { Loader } from "react-loaders";
import Api from "../../api";
import Translator from "../../translations";
import { login } from "../../actions/security";

class ResettingPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageStyle: "info",
      message: null,
      resetting: {
        password: null,
        confirmNewPassword: null,
      },
      fetching: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    let token =
      location && location.state && location.state.token
        ? location.state.token
        : this.props.token;
    if (!token && location && location.query && location.query.token) {
      token = location.query.token;
    }
    this.setState({ token });
  }

  resetting = () => {
    const { resetting, token } = this.state;
    const { history } = this.props;

    this.setState({ fetching: true });

    let requestData = {
      password: resetting.password,
      confirmNewPassword: resetting.confirmNewPassword,
      token,
    };
    Api.resettingPassword(JSON.stringify(requestData))
      .then((response) => {
        this.setState({
          message: response.message || response,
          messageStyle: response.type || "success",
          fetching: false,
        });
        const apiKey = response.apiKey || response.api_key;
        if (apiKey) {
          Api.getUserInfo(apiKey)
            .then((user) => {
              this.props.login(user);
              setTimeout(() => {
                history.push("/publications");
              }, 2000);
            })
            .catch(() => {
              setTimeout(() => {
                history.push("/login");
              }, 2000);
            });
        }
        this.props.onResetting && this.props.onResetting(response);
        !this.props.onResetting &&
          setTimeout(() => {
            history && history.push("/login");
          }, 2000);
      })
      .catch((error) => {
        this.setState({
          message: error.message || error,
          messageStyle: "danger",
          fetching: false,
        });
      });
  };
  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let modelName = e.target.dataset.model || null;

    let model = null;
    if (modelName) {
      model = { ...this.state[modelName] };
    }
    model[name] = value;

    this.setState({ [modelName]: model });
  };

  render() {
    const { messageStyle, message, fetching, token } = this.state;

    if (!token) {
      return <div />;
    }
    return (
      <div className="container-fluid margin-top-40 margin-bottom-40 text-center">
        <div className="page-title-base container">
          <h1 className="title-base">
            {Translator.t("change_password.title")}
          </h1>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-md-offset-4 col-md-4 col-md-offset-4  margin-bottom-40">
              {message && (
                <div
                  className={`flat text-center alert alert-${
                    messageStyle || "warning"
                  }`}
                >
                  {message}
                </div>
              )}

              {
                <form>
                  <div className="form-group">
                    <input
                      type="password"
                      disabled={fetching}
                      data-model="resetting"
                      name="password"
                      onChange={this.handleInput}
                      className="form-control"
                      placeholder={Translator.t("form.new_password")}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      disabled={fetching}
                      data-model="resetting"
                      name="confirmNewPassword"
                      onChange={this.handleInput}
                      className="form-control"
                      placeholder={Translator.t(
                        "form.new_password_confirmation"
                      )}
                    />
                  </div>
                  {fetching && (
                    <div className="margin-top-40 margin-bottom-40 ">
                      <Loader
                        type="ball-pulse-rise"
                        size="lg"
                        active={fetching}
                        styles={{ width: 45, height: 45 }}
                      />
                    </div>
                  )}
                  {!fetching && (
                    <button
                      type="submit"
                      disabled={fetching}
                      onClick={this.resetting}
                      className="btn btn-inline"
                    >
                      {Translator.t("profile.change_password")}
                    </button>
                  )}
                </form>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function bindAction(dispatch) {
  return {
    login: (user) => dispatch(login(user)),
  };
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, bindAction)(ResettingPassword);
