import React, { Component } from "react";

import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import lottie from "lottie-web";
import Kiosk from "./Kiosk";
import { setKiosk } from "./actions/appConfig";

//import animation from './assets/animations/animation-loading.json';
//import animation from './assets/animations/animation-w1080-h1080.json';
//import animation from './assets/animations/book-download.json';
//import animation from './assets/animations/book-loading-2.json';
//import animation from './assets/animations/check.json';
//import animation from './assets/animations/jumping-gift-box.json';

import animation from "./assets/animations/book-loading.json";
import api from "./api";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    configureStore((store) => {
      const appConfig = store.getState().appConfig;
 
      if (!appConfig || !appConfig.kiosk) {
        this.loadKiosk(store, () => {
          this.setState({ isLoading: false, store });
        });
      } else {
        this.loadKiosk(store);
        this.setState({ isLoading: false, store });
      }
    });
    lottie.loadAnimation({
      container: document.getElementById("svgContainer"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animation, // the path to the animation json
    });
  }

  loadKiosk(store, cb = () => {}) {
    api
      .getKiosk(process.env.REACT_APP_API_SECRETE)
      .then((kiosk) => {
        store.dispatch(setKiosk(kiosk));
        cb();
      })
      .catch(() => {
        cb();
      });
  }
  render() {
    if (this.state.isLoading) {
      return (
        <div
          id="svgContainer"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "#000",
            display: "inline-block",
          }}
        ></div>
      );
    }

    return (
      <Provider store={this.state.store}>
        <Kiosk />
      </Provider>
    );
  }
}
