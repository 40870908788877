import React, { Component } from 'react';

import { connect } from 'react-redux';

import ChangePasswordForm from './ChangePasswordForm';
import Translator from '../../translations';

class  ChangePassword extends Component {

  onChangePassword = () =>{
    setTimeout(()=>{
      this.props.history.push('/profile');
    },2500)
  }

  render() {
   const {apiKey} = this.props;

    return (
      <div className="container-fluid margin-top-40 margin-bottom-40 text-center">
          <div className="page-title-base container">
              <h1 className="title-base">{Translator.t('profile.change_password')}</h1>
          </div>
          <div className="container-fluid">
            <div className='row'>
              <div className='col-md-offset-4 col-md-4 col-md-offset-4'>
                   <ChangePasswordForm onChangePassword={this.onChangePassword} apiKey={apiKey} />
              </div>
            </div>

          </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  apiKey: state.security.user  ? state.security.user.apiKey : null ,
});

export default connect(mapStateToProps)(ChangePassword);
